import React from 'react';

const Close = ({ className }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.66689 0.281291C1.27926 -0.100357 0.658028 -0.0926839 0.279339 0.298428C-0.0993501 0.689541 -0.0920967 1.31599 0.29554 1.69763L6.69681 8L0.29554 14.3024C-0.0920967 14.684 -0.0993501 15.3105 0.279339 15.7016C0.658028 16.0927 1.27926 16.1004 1.66689 15.7187L8.10177 9.38325L14.3331 15.5183C14.7207 15.9 15.342 15.8923 15.7207 15.5012C16.0993 15.1101 16.0921 14.4836 15.7045 14.102L9.50673 8L15.7045 1.89803C16.0921 1.51639 16.0993 0.889941 15.7207 0.498829C15.342 0.107717 14.7207 0.100044 14.3331 0.481691L8.10177 6.61675L1.66689 0.281291Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Close;
