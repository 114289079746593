import { skipToken } from '@reduxjs/toolkit/query';
import { collection, query, where } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { myFirestore } from '../admin/config';
import { StripLabel } from '../common/generic/GeneralFunction';
import useAuth from '../common/hooks/useAuth';
import DisplayRichText from '../components/Common/DisplayRichText';
import Accordions from '../components/global/Accordions';
import {
    STATUS_COMPLETED,
    STATUS_INPROGRESS,
    STATUS_TOSTART,
} from '../components/global/ProgressCard';
import TheSpinner from '../components/global/TheSpinner';
import { useGetProjectDetailQuery } from '../features/projectsApi';

const Progress = () => {
    const [cardLabels, setCardLabels] = useState([]);
    const [currentStage, setCurrentStage] = useState(undefined);
    const [nextStage, setNextStage] = useState(undefined);
    const [selectedStage, setSelectedStage] = useState(undefined);

    const pageId = 'progress';
    const [pageMatches, pageLoading, pageError] = useCollectionDataOnce(
        query(collection(myFirestore, 'page'), where('slug', '==', pageId))
    );
    const pageData = pageMatches?.[0] || null;

    let { projectId } = useParams();
    const { user } = useAuth();
    const {
        data: project,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetProjectDetailQuery(
        projectId ? { projectId, token: user.accessToken } : skipToken
    );

    // const { data: content } = useGetPageContentQuery({
    //   pageName: "progress",
    //   token: user.accessToken,
    // });

    // const {
    //     data: phaseData,
    //     isLoading: phaseIsLoading,
    //     isSuccess: phaseIsSuccess,
    // } = useGetPhaseContentQuery(
    //     selectedStage && selectedStage.rawLabel
    //         ? {
    //               phaseName: selectedStage.rawLabel,
    //               token: user.accessToken,
    //           }
    //         : skipToken
    // );

    useEffect(() => {
        if (isSuccess && project && project.phases) {
            const cardLabels = [];
            let index = 0;
            project.phases.forEach(phase => {
                if (phase.type === 'phase') {
                    const cardLabel = createCardLabel({ phase, index });
                    if (cardLabel) {
                        cardLabels.push(cardLabel);
                        index = index + 1;
                    }
                }
            });

            let currentStage;
            if (cardLabels.some(x => x.status === STATUS_INPROGRESS)) {
                currentStage = cardLabels.filter(
                    x => x.status === STATUS_INPROGRESS
                )[0];
            } else if (cardLabels.some(x => x.status === STATUS_TOSTART)) {
                currentStage = cardLabels.filter(
                    x => x.status === STATUS_TOSTART
                )[0];
            }

            let nextStage;
            if (cardLabels.some(x => x.status === STATUS_TOSTART)) {
                nextStage = cardLabels.filter(
                    x => x.status === STATUS_TOSTART
                )[0];
            }

            setCurrentStage(currentStage);
            setNextStage(nextStage);
            if (currentStage) {
                setSelectedStage(currentStage);
            } else {
                setSelectedStage(cardLabels[cardLabels.length - 1]);
            }

            setCardLabels(cardLabels);
        }
    }, [isLoading, isSuccess, project]);

    function createCardLabel({ phase, index }) {
        if (!phase.start_date && !phase.end_date) {
            return;
        }

        let startDate;
        if (phase.start_date) {
            startDate = moment(phase.start_date);
        } else {
            startDate = moment(phase.end_date).add(-1, 'days');
        }

        let endDate;
        if (phase.end_date) {
            endDate = moment(phase.end_date);
        } else {
            endDate = moment(phase.start_date).add(1, 'days');
        }

        if (moment() >= startDate && moment() <= endDate) {
            const totalDays = moment.duration(startDate.diff(endDate)).asDays();
            const totalWorkDays = moment
                .duration(startDate.diff(moment()))
                .asDays();
            const percentageComplete = Math.ceil(
                (totalWorkDays / totalDays) * 100
            );
            return {
                label: StripLabel(phase.title),
                rawLabel: phase.title,
                status: STATUS_INPROGRESS,
                dateLabel: endDate.format('[ETA] D MMM, YYYY'),
                endDateBar: endDate.format('D MMM'),
                startDateBar: startDate.format('D MMM'),
                percentageComplete,
                index,
            };
        }
        if (moment() < startDate) {
            return {
                label: StripLabel(phase.title),
                rawLabel: phase.title,
                status: STATUS_TOSTART,
                dateLabel: startDate.format('[Start date] D MMM, YYYY'),
                endDateBar: endDate.format('D MMM'),
                startDateBar: startDate.format('D MMM'),
                index,
            };
        }
        if (moment() > endDate) {
            return {
                label: StripLabel(phase.title),
                rawLabel: phase.title,
                status: STATUS_COMPLETED,
                dateLabel: endDate.format('[on] D MMM, YYYY'),
                endDateBar: endDate.format('D MMM'),
                startDateBar: startDate.format('D MMM'),
                index,
            };
        }
    }

    function SelectPhase(cardPhase) {
        setSelectedStage(cardPhase);
    }

    function SelectIndex(index) {
        SelectPhase(cardLabels.filter(x => x.index === index)[0]);
    }

    function openVideo(url) {
        window.open(`https:${url}`, '_blank', 'noreferrer');
    }

    if (selectedStage && isSuccess && project && cardLabels) {
        return (
            <>
                <div>
                    <h1 className="text-3xl sm:text-5xl font-thin mb-5">
                        Project progress
                    </h1>
                    {/* <p className="font-bo ld text-lg mb-5">Project address - {project.project_name}</p> */}
                    <div className="w-full space-y-6">
                        {pageLoading ? (
                            <TheSpinner />
                        ) : (
                            <DisplayRichText content={pageData?.description} />
                        )}
                    </div>
                    <div className="mt-16">
                        <h2 className="text-2xl mb-5">Summary of each phase</h2>
                        <Accordions data={cardLabels}></Accordions>
                    </div>
                </div>
            </>
        );
    } else if (isLoading) {
        return (
            <span>
                <TheSpinner />
            </span>
        );
    } else if (isError) {
        return <span>{error.message}</span>;
    } else if (isSuccess && !project.phases) {
        return (
            <>
                <h1 className="text-3xl font-thin mb-5">
                    Your pool project is complete.
                </h1>
            </>
        );
    } else {
        return <></>;
    }
};

export default Progress;
