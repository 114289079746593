const TextInput = ({ register, errors, title, id }) => {
    return (
        <div className="flex flex-col border rounded px-2 pt-6 pb-3">
            <label htmlFor={id}>{title}:</label>
            <input
                id={id}
                type="text"
                {...register}
                className="border rounded px-2 py-1"
            />
            {errors[id] && (
                <span className="text-red-500 text-sm pt-3">
                    {errors[id].message}
                </span>
            )}
        </div>
    );
};

export default TextInput;
