import { collection, query, where } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { myFirestore } from '../admin/config';
import DisplayRichText from '../components/Common/DisplayRichText';
import TheSpinner from '../components/global/TheSpinner';
import { useSelectedProject } from '../features/projectSlice';
import './Calendar.css';

const CalendarPage = () => {
    const [dateValue, setDateValue] = useState(new Date());
    const [date, setDate] = useState(-1);
    const [month, setMonth] = useState('');

    const [selectedEvent, setSelectedEvent] = useState(undefined);
    const [modalState, setModalState] = useState(false);

    const [events, setEvents] = useState([]);

    const pageId = 'calendar';
    const [pageMatches, pageLoading, pageError] = useCollectionDataOnce(
        query(collection(myFirestore, 'page'), where('slug', '==', pageId))
    );
    const pageData = pageMatches?.[0] || null;

    // const { user } = useAuth();

    // const { data: content } = useGetPageContentQuery({
    //     pageName: 'calendar',
    //     token: user.accessToken,
    // });

    const localizer = momentLocalizer(moment);

    useEffect(() => {
        const selectedDate = moment(dateValue);
        setDate(selectedDate.get('date'));
        setMonth(selectedDate.format('MMM'));
    }, [dateValue]);

    const project = useSelectedProject();

    useEffect(() => {
        if (project?.calendar?.items?.length > 0 && !events?.length > 0) {
            setEvents(
                project.calendar.items.map(item => ({
                    start: moment(item.start_datetime).toDate(),
                    end: moment(item.end_datetime).toDate(),
                    title: item.event_name,
                }))
            );
        }
    }, [events?.length, project]);
    const handleSelectedEvent = event => {
        setSelectedEvent(event);
        setModalState(true);
    };

    const Modal = () => {
        return (
            <div
                className="relative z-10"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
            >
                <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"></div>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                        <div className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <h3
                                            className="text-base font-semibold leading-6 text-gray-900"
                                            id="modal-title"
                                        >
                                            {selectedEvent.title}
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {moment(
                                                    selectedEvent.start
                                                ).format('DD/MM/YYYY')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setSelectedEvent(null) &&
                                        setModalState(false)
                                    }
                                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-nav-600 hover:bg-nav-500 sm:ml-3 sm:w-auto"
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            <h1 className="mb-5 text-3xl font-thin sm:text-5xl">Calendar</h1>
            <div className="w-full space-y-6">
                {pageLoading ? (
                    <TheSpinner />
                ) : (
                    <DisplayRichText content={pageData?.description} />
                )}
            </div>
            <div className="flex flex-wrap mt-10 text-xs">
                <div className="w-full">
                    {selectedEvent && <Modal />}
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        onSelectSlot={e => handleSelectedEvent(e)}
                        onSelectEvent={e => handleSelectedEvent(e)}
                        style={{ height: 800 }}
                        formats={{
                            // dateFormat: 'd-MMM-yyyy',
                            agendaDateFormat: 'd-MMM-yyyy',
                            agendaHeaderFormat: ({ start, end }) => {
                                return (
                                    moment(start).format('d-MMM-yyyy') +
                                    ' - ' +
                                    moment(end).format('d-MMM-yyyy')
                                );
                            },
                        }}
                    />
                </div>
                {/* <div className="w-4/12 px-5 xl:w-3/12">
                    <p className="font-bold uppercase">Today</p>
                    <p className="text-4xl font-medium">{date}</p>
                    <p className="mb-3 uppercase">{month}</p>
                    <div className="font-bold text-center text-white uppercase bg-red-600 alert">
                        Provide asset protection permit
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default CalendarPage;
