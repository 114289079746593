import React, { useCallback } from 'react';
import { useSelectedProject } from '../../features/projectSlice';
import IconMenu from '../icons/Menu';
import IconUserCircle from '../icons/UserCircle';
import SidebarMain from '../sidebar/Main';

const MobileDrawer = ({ children, currentPath, topLinks, bottomLinks }) => {
    const project = useSelectedProject();

    const address = useCallback(() => {
        if (!project) return '';
        return project.custom_fields?.length > 0
            ? project.custom_fields.find(x => x.id === 'c_siteaddress')?.value
            : '';
    }, [project]);

    return (
        <div className="drawer drawer-end">
            <input
                id="mobile-drawer"
                type="checkbox"
                className="drawer-toggle"
            />
            <div className="drawer-content pt-[100px] md:pt-0">
                <div className="bg-darkBlue text-white flex justify-between items-center md:hidden py-5 px-5 fixed w-full z-10 top-0">
                    <div className="text-white flex items-center">
                        <div className="mr-3">
                            <IconUserCircle className="w-14" />
                        </div>
                        <div className="uppercase text-sm font-bold">
                            <div>{project?.company_name}</div>
                            <div>{address()}</div>
                        </div>
                    </div>
                    <label
                        htmlFor="mobile-drawer"
                        className="drawer-button cursor-pointer"
                    >
                        <IconMenu className="w-14" />
                    </label>
                </div>
                {children}
            </div>
            <div className="drawer-side">
                <label
                    htmlFor="mobile-drawer"
                    className="drawer-overlay"
                ></label>
                <SidebarMain
                    currentPath={currentPath}
                    topLinks={topLinks}
                    bottomLinks={bottomLinks}
                    isMobile={true}
                />
            </div>
        </div>
    );
};

export default MobileDrawer;
