import React from "react";

const UserCircle = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
      className={className}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="29.34px"
        fill="none"
        d="m367.07,323.73c-10.41-26.84-29.86-44.69-64.62-46.93-13.6,10.09-30.26,16.26-48.44,16.26s-34.85-6.16-48.49-16.26c-34.98,2.27-54.44,20.34-64.78,47.45m113.27-76.76c28.56,0,51.73-23.18,51.73-51.74s-23.18-51.76-51.73-51.76-51.74,23.2-51.74,51.76,23.14,51.74,51.74,51.74Zm195.32,2.51c0,110.09-89.24,199.33-199.33,199.33S50.67,360.09,50.67,250,139.91,50.67,250,50.67s199.33,89.24,199.33,199.33Z"
      />
    </svg>
  );
};

export default UserCircle;
