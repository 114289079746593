import { collection, query, where } from 'firebase/firestore';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import slugify from 'slugify';
import { myFirestore } from '../admin/config';
import { StripLabel } from '../common/generic/GeneralFunction';
import DisplayRichText from '../components/Common/DisplayRichText';
import {
    STATUS_COMPLETED,
    STATUS_INPROGRESS,
    STATUS_TOSTART,
} from '../components/global/ProgressCard';
import ProjectProgressBar from '../components/global/ProjectProgressBar';
import TheSpinner from '../components/global/TheSpinner';
import { useProjectPhases } from '../features/projectSlice';

const Phase = () => {
    const { phase } = useParams();
    // const { user } = useAuth();
    const { phases } = useProjectPhases();

    // const {
    //     data: phaseData,
    //     // isLoading: phaseIsLoading,
    //     isSuccess: phaseIsSuccess,
    // } = useGetPhaseContentQuery({
    //     phaseName: phase,
    //     token: user.accessToken,
    // });

    const [phaseMatches, phaseLoading, phaseError] = useCollectionDataOnce(
        query(collection(myFirestore, 'phase'), where('slug', '==', phase))
    );

    const phaseData = phaseMatches?.[0];

    // console.log('phase', phase, phaseData, phaseLoading, phaseError);

    const selectedPhase = useCallback(() => {
        if (!phase) return;

        const selected = phases.find(
            x => slugify(x.title || '', { lower: true }) === phase
        );

        if (!selected) return;

        if (!selected.start_date && !selected.end_date) {
            return;
        }

        let startDate;
        if (selected.start_date) {
            startDate = moment(selected.start_date);
        } else {
            startDate = moment(selected.end_date).add(-1, 'days');
        }

        let endDate;
        if (selected.end_date) {
            endDate = moment(selected.end_date);
        } else {
            endDate = moment(selected.start_date).add(1, 'days');
        }

        if (moment() >= startDate && moment() <= endDate) {
            const totalDays = moment.duration(startDate.diff(endDate)).asDays();
            const totalWorkDays = moment
                .duration(startDate.diff(moment()))
                .asDays();
            const percentageComplete = Math.ceil(
                (totalWorkDays / totalDays) * 100
            );
            return {
                label: StripLabel(selected.title),
                rawLabel: selected.title,
                status: STATUS_INPROGRESS,
                dateLabel: endDate.format('[ETA] D MMM, YYYY'),
                endDateBar: endDate.format('D MMM'),
                startDateBar: startDate.format('D MMM'),
                percentageComplete,
            };
        }

        if (moment() < startDate) {
            return {
                label: StripLabel(selected.title),
                rawLabel: selected.title,
                status: STATUS_TOSTART,
                dateLabel: startDate.format('[Start date] D MMM, YYYY'),
                endDateBar: endDate.format('D MMM'),
                startDateBar: startDate.format('D MMM'),
            };
        }

        if (moment() > endDate) {
            return {
                label: StripLabel(selected.title),
                rawLabel: phase.title,
                status: STATUS_COMPLETED,
                dateLabel: endDate.format('[on] D MMM, YYYY'),
                endDateBar: endDate.format('D MMM'),
                startDateBar: startDate.format('D MMM'),
            };
        }
    }, [phase, phases]);

    function openVideo(url) {
        window.open(`https:${url}`, '_blank', 'noreferrer');
    }

    if (!selectedPhase()) return <TheSpinner />;

    return (
        <div className="w-full">
            <h1 className="text-3xl sm:text-5xl font-thin mb-10">
                {selectedPhase().label}
            </h1>

            <div className="w-full">
                {selectedPhase().status === STATUS_TOSTART && (
                    <ProjectProgressBar
                        current={{
                            left: {
                                label: 'Start date',
                                value: selectedPhase().startDateBar,
                            },
                            progress: 14,
                        }}
                        next={{
                            right: {
                                label: 'Estimated Completion',
                                value: selectedPhase().endDateBar,
                            },
                        }}
                    />
                )}
                {selectedPhase().status === STATUS_INPROGRESS && (
                    <ProjectProgressBar
                        current={{
                            left: {
                                label: 'Start date',
                                value: selectedPhase().startDateBar,
                            },
                            center: {
                                label: 'Stage progress',
                                value: `${selectedPhase().percentageComplete}%`,
                            },
                            progress:
                                selectedPhase().percentageComplete < 40
                                    ? 40
                                    : selectedPhase().percentageComplete,
                        }}
                        next={{
                            right: {
                                label: 'Estimated Completion',
                                value: selectedPhase().endDateBar,
                            },
                        }}
                    />
                )}
                {selectedPhase().status === STATUS_COMPLETED && (
                    <ProjectProgressBar
                        current={{
                            left: {
                                label: 'Start date',
                                value: selectedPhase().startDateBar,
                            },
                            center: {
                                label: '',
                                value: 'Stage finished!',
                            },
                            right: {
                                label: 'Finished',
                                value: selectedPhase().endDateBar,
                            },
                            progress: 100,
                        }}
                        next={{}}
                        isComplete={true}
                    />
                )}

                {phaseLoading && (
                    <div className="mt-10 content">
                        <span>
                            <TheSpinner />
                        </span>
                    </div>
                )}

                {/* {JSON.stringify(phaseData)} */}

                <div className="w-full">
                    {phaseData?.description && (
                        <div className="mt-10 content">
                            {/* Note: this shows a message if the phase does not exist */}
                            <DisplayRichText content={phaseData?.description} />
                        </div>
                    )}
                    {phaseData?.overview && (
                        <div className="mt-10 content">
                            <p className="font-bold text-lg mb-3">
                                Stage Overview
                            </p>
                            <DisplayRichText content={phaseData?.overview} />
                        </div>
                    )}
                    {phaseData?.responsibilities && (
                        <div className="mt-10 content">
                            <p className="font-bold text-lg mb-3">
                                Client Responsibilities
                            </p>
                            <DisplayRichText
                                content={phaseData?.responsibilities}
                            />
                        </div>
                    )}
                    {phaseData?.video && (
                        <div className="py-6">
                            <iframe
                                title="video"
                                // width="420"
                                // height="315"
                                src={phaseData.video}
                                className="w-full aspect-video"
                            ></iframe>

                            {/* <video
                                    controls
                                    width="auto"
                                    height="auto"
                                    alt="sample video"
                                    className="w-full"
                                >
                                    <source
                                        src={phaseData.video}
                                        type="video/mp4"
                                    />
                                </video> */}

                            {/* <button
                                    className="btn btn-sm btn-outline text-xs font-normal normal-case btn-blue ml-5 rounded"
                                    onClick={() => openVideo(phaseData.video)}
                                >
                                    Watch video
                                </button> */}
                        </div>
                    )}
                    {phaseData?.documents && (
                        <div className="mt-10 content">
                            <p className="font-bold text-lg mb-3">
                                Related documents{' '}
                                {/* <button className="btn btn-sm font-normal text-xs normal-case btn-outline btn-blue ml-5 rounded">
                                        Go to documents
                                    </button> */}
                            </p>
                            <DisplayRichText content={phaseData?.documents} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Phase;
