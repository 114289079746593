import './ProjectProgressBar.css';

const ProjectProgressBar = ({ current, next, isComplete = false }) => {
    const barStyle = {
        background: 'bg-nav-100',
    };
    const currentStyle = {
        width: current.progress + '%',
    };
    const finishStyle = {
        width: current.progress + '%',
    };
    const nextStyle = {
        width: 100 - current.progress + '%',
    };

    return (
        <>
            <div className="flex project-progress-bar" style={barStyle}>
                <div
                    className={`current flex justify-between flex-row ${
                        isComplete ? 'bg-nav-100' : 'bg-nav-500'
                    }`}
                    style={isComplete ? finishStyle : currentStyle}
                >
                    {current.left && current.left.value !== '' && (
                        <div>
                            <span className="block text-xs">
                                {current.left.label}
                            </span>
                            <span className="block font-semibold text-sm">
                                {current.left.value}
                            </span>
                        </div>
                    )}
                    {current.center && current.center.value !== '' && (
                        <div className={`text-center`}>
                            <span className="block font-semibold lg:text-2xl">
                                {current.center.value}
                            </span>
                            <span className="block text-xs">
                                {current.center.label}
                            </span>
                        </div>
                    )}
                    {current.right && current.right.value !== '' && (
                        <div className={`text-right`}>
                            <span className="block text-xs">
                                {current.right.label}
                            </span>
                            <span className="block font-semibold text-sm">
                                {current.right.value}
                            </span>
                        </div>
                    )}
                </div>
                {!isComplete && (
                    <div
                        className="next flex justify-between flex-row"
                        style={nextStyle}
                    >
                        <div>
                            {next &&
                            next.left &&
                            next.left.value !== '' &&
                            current.progress < 80 ? (
                                <>
                                    <span className="block text-xs">
                                        {next.left.label}
                                    </span>
                                    <span className="block font-semibold text-sm">
                                        {next.left.value}
                                    </span>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className={'text-right'}>
                            {next && next.right && next.right.value !== '' ? (
                                <>
                                    <span className="block text-xs">
                                        {next.right.label}
                                    </span>
                                    <span className="block font-semibold text-sm">
                                        {next.right.value}
                                    </span>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ProjectProgressBar;
