import { Link } from 'react-router-dom';

const AdminSidebar = () => {
    return (
        <div>
            <div className="text-3xl">Admin</div>
            <div className="pt-6 space-y-1">
                <Link
                    to="/admin"
                    className="block px-3 py-2 bg-gray-50 hover:bg-gray-100"
                >
                    Dashboard
                </Link>
                <Link
                    to="/admin/page"
                    className="block px-3 py-2 bg-gray-50 hover:bg-gray-100"
                >
                    Pages
                </Link>
                <Link
                    to="/admin/phase"
                    className="block px-3 py-2 bg-gray-50 hover:bg-gray-100"
                >
                    Phases
                </Link>
                <Link
                    to="/project"
                    className="block px-3 p-2 bg-gray-50 hover:bg-gray-100"
                >
                    back...
                </Link>
            </div>
        </div>
    );
};

export default AdminSidebar;
