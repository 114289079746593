import _ from 'lodash';
import React, { useState } from 'react';
import './UpdateList.css';
const UpdateList = ({ data }) => {
  const [filterActive, setfilterActive] = useState(false);
  const [tagFilter, settagFilter] = useState('');
  const [posts, setPosts] = useState(data);

  const toggleFilterClass = () => {
    setfilterActive(!filterActive);
  };

  const tags = data.filter((post, i) => data.findIndex((row) => row.tag === post.tag) >= i);

  const filterList = (tag) => {
    settagFilter(tag);
    setPosts(tag === '' ? data : data.filter((v) => _.toLower(v.tag) === tag));
  };

  return (
    <div className="update-wrapper">
      <div className="update-filter">
        <div className="filter-wrapper flex mb-3">
          <div className={filterActive ? 'w-1/2 lg:w-9/12 filter-active' : 'w-1/2 lg:w-9/12'}>
            <span
              onClick={() => {
                toggleFilterClass();
              }}
              className="mobile-filter border-b border-solid inline-block lg:hidden">
              Filter
            </span>
            <ul className="tags hidden bg-gray-100 lg:bg-opacity-0 lg:block">
              <li
                className={
                  tagFilter === ''
                    ? 'active lg:inline px-2 py-2 border-b'
                    : 'lg:inline px-2 py-2 border-b'
                }
                onClick={() => filterList('')}>
                All
              </li>
              {tags.map((tag) => (
                <li
                  className={
                    tagFilter === _.toLower(tag.tag)
                      ? 'active lg:inline px-2 py-2 border-b'
                      : 'lg:inline px-2 py-2 border-b'
                  }
                  onClick={() => filterList(_.toLower(tag.tag))}>
                  {_.startCase(_.toLower(tag.tag))}
                </li>
              ))}
            </ul>
          </div>
          <div className="w-1/2 lg:w-3/12 float-right text-right">
            <input
              type="text"
              placeholder="Search"
              className="w-full border search-input border-solid"
            />
          </div>
        </div>
      </div>
      {posts ? (
        <>
          <div className="update-list">
            {posts.map((post) => (
              <div
                className={'rounded-xl mb-2 p-4 post post-' + post.id}
                data-tag={_.toLower(post.tag)}>
                <h3 className="font-bold mb-2">{post.title}</h3>
                <p className="text-xs mb-2">{post.date}</p>
                <p className="mb-2">{post.description}</p>
                <a href={post.url} className="btn btn-secondary btn-sm font-normal normal-case">
                  Read more
                </a>
              </div>
            ))}
          </div>
          <div className="btn-group pagination my-10">
            <button className="btn rounded mr-1 btn-outline hover:bg-brand border-shade hover:border-brand">
              1
            </button>
            <button className="btn rounded mr-1 btn-primary bg-brand hover:bg-brand hover:border-brand">
              2
            </button>
            <button className="btn rounded mr-1 btn-disabled bg-transparent hover:bg-brand">
              ...
            </button>
            <button className="btn rounded mr-1 btn-outline hover:bg-brand border-shade hover:border-brand">
              99
            </button>
            <button className="btn rounded mr-1 btn-outline hover:bg-brand border-shade hover:border-brand">
              100
            </button>
          </div>
        </>
      ) : (
        <div>No Updates found</div>
      )}
      <div className="update-pagination"></div>
    </div>
  );
};

export default UpdateList;
