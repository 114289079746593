import React from 'react';

const Document = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
      className={className}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18.12px"
        fill="none"
        d="m354.3,184.4v37.25m-211.31-37.25v37.25m211.31,72.53v112.47m-211.31-112.47v112.47M317.98,91.78c-11.14-27.55-38.02-47.02-69.55-47.02s-58.37,19.47-69.49,47.02M45.06,455.24h409.88V135.81H45.06v319.42Zm329.42-162.05h-40.33m-170.98,0h-40.33"
      />
    </svg>
  );
};

export default Document;
