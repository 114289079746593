import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import useAuth from '../../common/hooks/useAuth';
import MobileDrawer from '../Common/MobileDrawer';
import SidebarMain from '../sidebar/Main';

const MainLayout = () => {
    const { pathname } = useLocation();
    const { logOut } = useAuth();

    const callLogout = () => {
        if (window.confirm('Are you sure want to logout?')) {
            logOut();
        }
    };

    const bottomLinks = [
        // {
        //     label: 'Contact Us',
        //     link: '#',
        //     type: 'external',
        // },
        // {
        //     label: 'My Pool Drawings',
        //     link: '#',
        //     type: 'external',
        // },
        // {
        //     label: 'Spasa Contract',
        //     link: '#',
        //     type: 'external',
        // },
        {
            label: 'Log out',
            type: 'button',
            action: callLogout,
        },
    ];

    return (
        <MobileDrawer currentPath={pathname} bottomLinks={bottomLinks}>
            <div className="flex h-[100vh]">
                <StickyBox className="h-[100vh]">
                    <div className="hidden md:block h-full">
                        <SidebarMain
                            currentPath={pathname}
                            bottomLinks={bottomLinks}
                        />
                    </div>
                </StickyBox>
                <div className="flex-1 overflow-hidden">
                    <div className="overflow-auto px-2 sm:px-16 h-full py-6">
                        <Outlet />
                    </div>
                </div>
            </div>
        </MobileDrawer>
    );
};

export default MainLayout;
