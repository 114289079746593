import React from 'react';
import { RevolvingDot, ThreeDots } from 'react-loader-spinner';

const TheSpinner = ({ size = 100, type }) => {
  if (type === 'tree-dots') {
    return (
      <div className="flex justify-center">
        <ThreeDots type="Puff" color="#00947E" height={size} width={size} timeout={3000} />
      </div>
    )
  }
  return (
    <div className="flex justify-center">
      <RevolvingDot type="Puff" color="#00947E" height={size} width={size} timeout={3000} />
    </div>
  );
};

export default TheSpinner;
