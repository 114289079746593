import { skipToken } from '@reduxjs/toolkit/query';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import slugify from 'slugify';
import { StripLabel } from '../../common/generic/GeneralFunction';
import useAuth from '../../common/hooks/useAuth';
import ProjectStatusTabs from '../../components/navigation/ProjectStatusTabs';
import { select, useProjectPhases } from '../../features/projectSlice';
import {
    useGetProjectDetailQuery,
    useProjectCount,
} from '../../features/projectsApi';
import MobileDrawer from '../Common/MobileDrawer';
import IconChartBoard from '../icons/ChartBoard';
import IconDashboard from '../icons/Dashboard';
import IconDate from '../icons/Date';
import IconDocument from '../icons/Document';
import IconSetting from '../icons/Setting';
import SidebarActivity from '../sidebar/Activity';
import SidebarClient from '../sidebar/Client';
import SidebarClientTask from '../sidebar/ClientTask';
import SidebarMain from '../sidebar/Main';

const ProjectDetailLayout = () => {
    const { projectId } = useParams();
    const { pathname } = useLocation();
    const { logOut } = useAuth();
    const { user } = useAuth();
    const dispatch = useDispatch();

    const [openTab, setOpenTab] = useState();

    const { data } = useGetProjectDetailQuery(
        projectId ? { projectId, token: user.accessToken } : skipToken
    );

    const count = useProjectCount();

    useEffect(() => {
        if (data) {
            dispatch(select(data));
        }
    }, [dispatch, data]);

    const { current, phases } = useProjectPhases();

    const projectPhaseMenus = useMemo(() => {
        if (!phases?.length && !current) return [];

        return phases.map(phase => {
            return {
                label: StripLabel(phase.title),
                link: `/project/${projectId}/phase/${slugify(phase.title, {
                    lower: true,
                })}`,
                isExternal: false,
                complete: moment(phase.end_date) < moment(),
                current: current?.title === phase.title,
            };
        });
    }, [current, phases, projectId]);

    const topLinks = [
        {
            label: 'Dashboard',
            link: `/project/${projectId}/dashboard`,
            icon: <IconDashboard className="w-[30px]" />,
        },
        {
            label: 'Pool Build Process',
            link: `/project/${projectId}/progress`,
            icon: <IconSetting className="w-[30px]" />,
            subMenus: phases?.map(phase => ({
                label: phase.title,
                link: `/project/${projectId}/phase/${slugify(phase.title, {
                    lower: true,
                })}`,
                isExternal: false,
            })),
        },
        // {
        //     label: 'Warranty',
        //     link: `/project/${projectId}/warranty`,
        //     icon: <IconWarranty className="w-[30px]" />,
        // },
        {
            label: 'Calendar',
            link: `/project/${projectId}/calendar`,
            icon: <IconDate className="w-[30px]" />,
        },
        {
            label: 'Invoices',
            link: `/project/${projectId}/invoices`,
            icon: <IconChartBoard className="w-[30px]" />,
        },
        {
            label: 'My Pool/Documents',
            link: `/project/${projectId}/documents`,
            icon: <IconDocument className="w-[30px]" />,
        },
    ];

    const callLogout = () => {
        if (window.confirm('Are you sure want to logout?')) {
            logOut();
        }
    };

    const bottomLinks = [];

    if (count > 1) {
        bottomLinks.push({
            label: '< Project',
            link: '/project',
        });
    }

    bottomLinks.push({
        label: 'Log out',
        type: 'button',
        action: callLogout,
    });

    return (
        <>
            <MobileDrawer
                currentPath={pathname}
                topLinks={topLinks}
                bottomLinks={bottomLinks}
            >
                <div className="flex h-[100vh]xx items-start">
                    <StickyBox className="h-[100vh]">
                        <div className="hidden md:block h-full">
                            <SidebarMain
                                currentPath={pathname}
                                topLinks={topLinks}
                                bottomLinks={bottomLinks}
                            />
                        </div>
                    </StickyBox>

                    <div className="flex-1 min-w-0">
                        <div className="px-2 pt-2">
                            <div className="md:hidden relative flex items-center justify-end">
                                <SidebarActivity
                                    isButton={true}
                                    openTab={openTab}
                                    setOpenTab={setOpenTab}
                                />
                                <SidebarClientTask
                                    isButton={true}
                                    openTab={openTab}
                                    setOpenTab={setOpenTab}
                                />
                            </div>
                        </div>

                        <div className="px-8 md:px-16 my-10">
                            <ProjectStatusTabs tabs={projectPhaseMenus} />
                        </div>

                        <div className="flex flex-col-reverse md:flex-row h-full items-start">
                            <div className="w-full px-8 md:px-16 h-full pt-6 pb-24 flex-1">
                                <Outlet />
                            </div>

                            <StickyBox
                                offsetTop={20}
                                offsetBottom={20}
                                className="w-full md:w-auto px-8 md:px-0 relative hidden md:block"
                            >
                                <div className="md:w-[280px] flex-shrink-0 py-10">
                                    <div className="mb-4 hidden md:block">
                                        <SidebarClient />
                                    </div>
                                    <div className="mb-4">
                                        <SidebarActivity />
                                    </div>
                                    {/* <div>
                                    <SidebarClientTask />
                                </div> */}
                                </div>
                            </StickyBox>
                        </div>
                    </div>
                </div>
            </MobileDrawer>
        </>
    );
};

export default ProjectDetailLayout;
