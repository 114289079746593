import classNames from "classnames";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelectedProject } from '../../features/projectSlice';
import HoverComponent from '../Common/HoverComponent';
import ChevronDown from '../icons/ChevronDown';
import ChevronUp from '../icons/ChevronUp';

const SidebarClientTask = ({ isButton, openTab, setOpenTab }) => {
    const [data, setData] = useState([]);

    const project = useSelectedProject();

    useEffect(() => {
        const tasks = (project?.tasks?.items || []).slice(0, 4);
        setData(
            tasks.map(x => ({
                date: x.datetime_due
                    ? moment(x.datetime_due).format('DD/MM/YYYYY')
                    : '',
                label: x.event_name,
                status: moment(x.datetime_due) > moment() ? 'error' : '',
            }))
        );
    }, [project]);

    const content = (
        <ul>
            {data?.length > 0 ? (
                data.map(({ title, date, label, status }, index) => {
                    const isLast = index === data.length - 1;

                    const liClass = classNames(
                        'relative pl-6',
                        { 'pb-[40px]': !isLast },
                        {
                            'text-[#dc6268]': status === 'error',
                        }
                    );

                    return (
                        <li className={liClass} key={index}>
                            {status === 'error' ? (
                                <HoverComponent
                                    containerClass="absolute left-0 z-2 top-[3px]"
                                    toggleComponent={
                                        <div className="rounded-full flex items-center justify-center w-[16px] h-[16px] bg-[#dc6268] text-white text-sm">
                                            !
                                        </div>
                                    }
                                    panelClass="top-1/2 -translate-y-1/2 z-10 right-[24px]"
                                    panelComponent={
                                        <div
                                            open={false}
                                            static
                                            className="w-[200px] bg-white shadow rounded-2xl px-3 py-2 [&>p]:text-xs [&>p]:text-center [&>p]:text-[#dc6268] [&>p]:normal-case"
                                        >
                                            <p>
                                                <strong>
                                                    This task is now overdue.
                                                </strong>
                                            </p>
                                            <p>
                                                To avoid project delays, please
                                            </p>
                                            <p>action as soon as possible.</p>
                                        </div>
                                    }
                                ></HoverComponent>
                            ) : (
                                <div className="rounded-full absolute left-[24px] z-2 flex items-center justify-center w-[11px] h-[11px] top-[6px] bg-[#8e92a0] -translate-x-5"></div>
                            )}

                            {title && (
                                <div className="absolute font-bold -mt-12">
                                    {title}
                                </div>
                            )}
                            <div className="absolute -mt-6">{date}</div>
                            <div className="font-bold ">{label}</div>
                        </li>
                    );
                })
            ) : (
                <p>No upcoming tasks.</p>
            )}
        </ul>
    );

    const title = isButton ? 'Tasks' : 'Client Tasks';

    const isShowContent = openTab === 'tasks';

    const handleClick = () => {
        setOpenTab(isShowContent ? null : 'tasks');
    };

    if (isButton) {
        return (
            <>
                <button
                    className={classNames(
                        'py-2 font-bold mb-0 text-[#8e9295] text-lg rounded-large z-2 relative flex items-center justify-between w-full ml-auto max-w-1/2',
                        'px-8 bg-[#f2f2f2] shadow-md'
                    )}
                    onClick={handleClick}
                >
                    {title}{' '}
                    {isShowContent ? (
                        <ChevronDown className="ml-2" />
                    ) : (
                        <ChevronUp className="ml-2" />
                    )}
                </button>

                {isShowContent && (
                    <div className="bg-[#f2f2f2] absolute z-50 pt-20 pb-10 left-0 top-12 w-full rounded-large px-12">
                        {content}
                    </div>
                )}
            </>
        );
    }

    return (
        <div className="w-full shadow-2xl bg-[#f3f3f4] rounded-l-large rounded-r-large md:rounded-r-none py-5 px-10 text-[#8e9295] uppercase">
            <h3 className="font-bold mb-8 text-[#8e9295] text-lg">{title}</h3>
            {content}
        </div>
    );
};

export default SidebarClientTask;
