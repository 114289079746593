import React from 'react';

const ChartBoard = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
      className={className}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16.3px"
        fill="none"
        d="m303.26,376.38h-58.57m58.57-52.45h-114.47m103.61-240.68h-39.03c-3.26-22.09-6.88-39.1-29.85-39.1s-26.83,17.01-30.11,39.1h-45.07m-58.97,15.95h-25.57v356.65h311.7V99.2h-23.9m-46.47,82.3l-62.21-13.82-45.29,55.05-34.71-15.73-29.49,40.39m302.54,174.8l.22-274.33"
      />
    </svg>
  );
};

export default ChartBoard;
