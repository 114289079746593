import { forEach } from 'lodash';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import TheSpinner from './TheSpinner';

const getColor = props => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#B0B0B0';
};
const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // padding: 40px 20px;
    // border-width: 2px;
    // border-radius: 2px;
    // border-color: ${props => getColor(props)};
    // border-style: dashed;
    // background-color: #fff;
    color: #b5b5b5;
    // outline: none;
    transition: border 0.24s ease-in-out;
    text-transform: uppercase;
`;

const FileUpload = ({ onFileUpload, isLoading = false }) => {
    const onDrop = useCallback(acceptedFiles => {
        forEach(acceptedFiles, file => {
            const reader = new FileReader();

            reader.onload = event => {
                onFileUpload({
                    fileName: file.name,
                    fileSize: file.size,
                    chunkData: event.target.result.split(',')[1],
                });
            };

            reader.readAsDataURL(file);
        });
    }, []);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            'image/*': [],
            'text/*': ['.txt'],
            'application/pdf': ['.pdf'],
            'image/x-dwg': ['.dwg'],
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                ['.pptx'],
            'video/*': [],
        },
        onDrop,
        disabled: isLoading,
    });

    return (
        <div className="file-upload-wrapper mt-3">
            <Container
                {...getRootProps({ isFocused, isDragAccept, isDragReject })}
            >
                <input {...getInputProps()} />
                {!isLoading && (
                    <p>
                        <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            width="900.000000pt"
                            height="900.000000pt"
                            viewBox="0 0 900.000000 900.000000"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <g
                                transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
                                fill="#000000"
                                stroke="none"
                            >
                                <path
                                    d="M2995 7285 c-260 -47 -431 -207 -505 -473 -20 -70 -20 -109 -20
         -1979 l0 -1908 24 -77 c68 -221 226 -380 441 -444 55 -17 127 -18 866 -21
         l807 -4 27 -27 c15 -15 52 -61 83 -102 30 -41 109 -129 176 -195 262 -260 555
         -401 946 -456 93 -12 347 -7 440 10 198 36 314 73 487 156 338 162 590 428
         755 796 71 160 98 246 128 417 54 306 0 652 -149 952 -170 341 -413 591 -726
         747 -55 28 -122 58 -150 68 -27 9 -64 24 -82 31 -17 8 -37 14 -45 14 -7 0 -38
         9 -68 19 l-55 18 -6 544 c-6 540 -6 544 -29 588 -20 40 -153 184 -519 566
         -216 225 -297 310 -476 500 -215 228 -218 230 -258 253 -30 16 -100 17 -1027
         19 -764 1 -1011 -2 -1065 -12z m1915 -625 c4 -282 10 -414 18 -433 7 -16 12
         -37 12 -48 0 -37 72 -168 119 -216 61 -64 114 -98 197 -130 l69 -27 385 -4
         c215 -2 392 -8 402 -13 17 -9 18 -39 18 -459 0 -433 -1 -450 -19 -460 -11 -5
         -73 -12 -138 -15 -258 -12 -470 -67 -680 -175 -86 -45 -142 -80 -288 -183
         l-60 -42 -770 -5 c-563 -4 -777 -8 -796 -17 -70 -31 -82 -142 -21 -188 26 -19
         47 -20 687 -25 l660 -5 -2 -21 c-1 -11 -29 -67 -63 -123 -33 -55 -60 -103 -60
         -106 0 -19 -68 -108 -91 -120 -24 -13 -112 -15 -569 -15 l-542 0 -29 -29 c-23
         -24 -29 -38 -29 -73 0 -53 21 -85 69 -106 31 -13 112 -16 536 -19 575 -4 517
         9 491 -117 -19 -87 -22 -435 -5 -501 5 -22 14 -65 19 -95 5 -30 19 -86 31
         -125 12 -38 23 -89 26 -112 l5 -43 -712 0 c-753 0 -774 1 -865 47 -91 46 -165
         142 -201 259 -17 56 -18 154 -15 1934 2 2036 -1 1913 58 2021 30 56 97 120
         156 150 94 48 82 48 1067 46 l925 -2 5 -405z m302 171 c7 -10 96 -105 198
         -212 260 -272 374 -392 459 -481 51 -54 72 -82 67 -93 -5 -15 -35 -16 -293
         -13 l-288 3 -51 27 c-60 32 -109 84 -135 145 -17 41 -19 72 -19 347 0 167 3
         306 6 309 9 9 40 -9 56 -32z m1113 -2236 c33 -9 85 -24 115 -32 30 -9 64 -19
         75 -24 11 -4 45 -18 75 -30 68 -28 239 -129 300 -177 230 -183 421 -461 484
         -705 8 -31 17 -61 20 -65 15 -24 40 -233 40 -337 2 -385 -155 -752 -438 -1022
         -178 -170 -389 -288 -623 -348 -88 -22 -256 -45 -335 -45 -86 0 -272 26 -365
         51 -300 80 -572 262 -752 503 -143 192 -204 324 -268 581 -25 101 -25 448 0
         555 106 450 380 797 777 986 123 59 130 61 285 105 77 21 171 28 360 25 143
         -3 205 -8 250 -21z"
                                />
                                <path
                                    d="M3383 5031 c-47 -22 -63 -45 -63 -93 0 -53 9 -72 44 -96 l32 -22 643
         0 644 0 33 36 c41 43 46 96 13 138 -44 57 -38 56 -698 56 -573 0 -608 -1 -648
         -19z"
                                />
                                <path
                                    d="M5969 4112 c-29 -19 -29 -19 -119 -117 -35 -38 -123 -133 -195 -210
         -414 -442 -435 -467 -435 -516 0 -53 59 -119 106 -119 50 0 140 86 503 482 31
         34 61 58 70 56 14 -3 17 -71 21 -660 l5 -658 30 -30 c43 -42 90 -49 135 -18
         20 14 40 38 47 58 9 26 12 205 12 668 l1 632 24 0 c13 0 26 -4 28 -9 2 -4 57
         -66 123 -137 66 -71 156 -167 199 -214 148 -159 182 -182 235 -160 44 18 72
         53 78 98 7 52 -3 66 -181 257 -77 83 -196 211 -265 285 -69 74 -168 179 -221
         233 -89 90 -99 97 -136 97 -21 0 -51 -8 -65 -18z"
                                />
                            </g>
                        </svg>
                        Uploads Documents / Files
                    </p>
                )}
                {isLoading && (
                    <p>
                        <TheSpinner size={60} type="tree-dots" />
                    </p>
                )}
            </Container>
        </div>
    );
};

export default FileUpload;
