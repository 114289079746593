import classNames from 'classnames';
import React from 'react';
import Link from '../Common/Link';
import IconClose from '../icons/Close';
import IconLogoWhite from '../icons/LogoWhite';

const SidebarMain = ({ currentPath, topLinks, bottomLinks, isMobile }) => {
    const menuItemClick = () => {
        if (isMobile) {
            document.getElementById('mobile-drawer').click();
        }
    };

    return (
        <div
            className={classNames(
                'w-[340px] shadow-2xl h-full text-white flex flex-col justify-between bg-darkBlue pt-10 overflow-auto',
                isMobile ? 'rounded-l-large' : 'rounded-r-large'
            )}
        >
            <div>
                <div className="mb-10">
                    <Link to="/">
                        <IconLogoWhite className="h-[80px] mx-auto" />
                    </Link>
                </div>

                {isMobile && (
                    <label
                        htmlFor="mobile-drawer"
                        className="drawer-button cursor-pointer absolute top-[20px] right-[10px]"
                    >
                        <IconClose className="w-14" />
                    </label>
                )}

                {topLinks && (
                    <ul>
                        {topLinks.map(
                            (
                                { icon, label, link, isExternal, subMenus },
                                menuIndex
                            ) => {
                                const isActive =
                                    currentPath === link ||
                                    subMenus?.findIndex(
                                        subMenu => subMenu.link === currentPath
                                    ) >= 0;

                                return (
                                    <li
                                        key={menuIndex}
                                        className={classNames('mb-4')}
                                    >
                                        <div
                                            className={classNames(
                                                'flex items-center relative px-8 py-[6px]',
                                                {
                                                    'before:content-[""] before:w-[6px] before:bg-[#00a2ae] before:absolute before:left-0 before:h-full before:rounded-r':
                                                        isActive,
                                                }
                                            )}
                                        >
                                            <Link
                                                to={link}
                                                isExternal={isExternal}
                                                className="inline-block align-middle"
                                                onClick={menuItemClick}
                                            >
                                                <div className="flex items-center">
                                                    <span className="mr-3">
                                                        {icon}
                                                    </span>
                                                    <span
                                                        className={classNames(
                                                            'uppercase',
                                                            {
                                                                'font-bold ':
                                                                    isActive,
                                                            }
                                                        )}
                                                    >
                                                        {label}
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        {subMenus && (
                                            <ul className="pl-[80px]">
                                                {subMenus.map(
                                                    (
                                                        { label, link },
                                                        submenuIndex
                                                    ) => {
                                                        return (
                                                            <li
                                                                className={`mb-1 my-4 ${
                                                                    link ===
                                                                    window
                                                                        .location
                                                                        .pathname
                                                                        ? ' font-bold'
                                                                        : ''
                                                                }`}
                                                                key={`${menuIndex}-${submenuIndex}`}
                                                            >
                                                                <Link to={link}>
                                                                    <span
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: label,
                                                                        }}
                                                                    />
                                                                </Link>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        )}
                                    </li>
                                );
                            }
                        )}
                    </ul>
                )}
            </div>
            <div className="px-8 pb-8 mt-10 mb-24">
                <p className="uppercase font-bold mb-2">Quick Links</p>
                <ul>
                    {bottomLinks?.map(
                        ({ label, type, action, link }, menuIndex) => {
                            const isActive = currentPath === link;

                            return (
                                <li
                                    key={menuIndex}
                                    className={classNames('mb-1', {
                                        'font-bold': isActive,
                                    })}
                                >
                                    {type === 'button' ? (
                                        <button
                                            className="uppercase text-sm"
                                            onClick={action}
                                        >
                                            {label}
                                        </button>
                                    ) : (
                                        <Link
                                            to={link}
                                            isExternal={type === 'external'}
                                            className="uppercase text-sm"
                                        >
                                            {label}
                                        </Link>
                                    )}
                                </li>
                            );
                        }
                    )}
                </ul>
            </div>
        </div>
    );
};

export default SidebarMain;
