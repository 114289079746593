import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useProjectPhases } from '../../features/projectSlice';
import ChevronDown from '../icons/ChevronDown';
import ChevronUp from '../icons/ChevronUp';

const SidebarActivity = ({ isButton, openTab, setOpenTab }) => {
    const { previous, current, next, phases } = useProjectPhases();

    const datas = [
        {
            date: previous?.start_date,
            label: previous?.title,
        },
        {
            date: current?.start_date,
            label: current?.title,
        },
        {
            title: 'Coming Up',
            date: next?.start_date,
            label: next?.title,
        },
    ];

    const content = (
        <ul>
            {phases.length > 0 &&
                datas.map(({ title, date, label }, index) => {
                    const isLast = index === datas.length - 1;
                    const isBeforeLast = index === datas.length - 2;

                    const isLastClass = `pb-[80px] before:bg-[#99dadf] after:absolute after:h-full after:left-[5px] after:top-[10px] after:border-r ${
                        isBeforeLast
                            ? 'after:border-[#8e92a0]'
                            : 'after:border-[#99dadf]'
                    }`;

                    const liClass = classNames(
                        'relative pl-6 before:absolute before:left-0 before:top-[9px] before:rounded-full before:w-[11px] before:h-[11px] before:z-2',
                        isLast ? 'before:bg-[#8e92a0]' : isLastClass
                    );

                    return (
                        <li className={liClass} key={index}>
                            {title && (
                                <div className="absolute font-bold -mt-12">
                                    {title}
                                </div>
                            )}
                            <div className="absolute -mt-6">
                                {date ? moment(date).format('DD/MM/YYYY') : ''}
                            </div>
                            <div className="font-bold ">{label}</div>
                        </li>
                    );
                })}
        </ul>
    );

    const title = isButton ? 'Activity' : 'Latest Activity';

    const isShowContent = openTab === 'activity';

    const handleClick = () => {
        setOpenTab(isShowContent ? null : 'activity');
    };

    if (isButton) {
        return (
            <>
                <button
                    className={classNames(
                        'py-2 font-bold mb-0 text-[#777c81] text-lg rounded-large z-2 relative flex items-center justify-between w-full ml-auto max-w-1/2',
                        'px-8 bg-[#f2f2f2] shadow-md'
                    )}
                    onClick={handleClick}
                >
                    {title}{' '}
                    {isShowContent ? (
                        <ChevronDown className="ml-2" />
                    ) : (
                        <ChevronUp className="ml-2" />
                    )}
                </button>
                {isShowContent && (
                    <div className="bg-[#f2f2f2] absolute z-50 pt-20 pb-10 left-0 top-12 w-full rounded-large px-12">
                        {content}
                    </div>
                )}
            </>
        );
    }

    return (
        <div className="w-full shadow-2xl bg-white rounded-l-large rounded-r-large md:rounded-r-none py-5 px-10 text-[#8e9295] uppercase sidebar-project">
            <h3 className="font-bold mb-8 text-[#8e9295] text-lg">{title}</h3>
            <div className="pb-10">{content}</div>
        </div>
    );
};

export default SidebarActivity;
