import React from 'react';
import { useForm } from 'react-hook-form';
import useAuth from '../../common/hooks/useAuth';
import TheSpinner from '../../components/global/TheSpinner';

const Forgot = () => {
  // use auth hook
  const { isLoading, isReset, resetPass, authError } = useAuth();
  // react hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // subnitting reset form
  const onSubmit = (data) => {
    resetPass(data.email);
    reset({});
  };

  return (
      <>
          {isLoading ? (
              <TheSpinner />
          ) : (
              <div className="flex h-screen">
                  <div className="m-auto">
                      <div className="w-56 py-10 m-auto">
                          <img
                              src="/images/new-logo.png"
                              alt="Watertyt"
                              className="h-full w-full object-cover object-center"
                          />
                      </div>
                      <div className="card w-96 bg-white rounded-xl md:shadow-xl p-10">
                          <form
                              onSubmit={handleSubmit(onSubmit)}
                              className="space-y-6"
                              action="#"
                              method="POST"
                          >
                              <div>
                                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                      Forgot password
                                  </h2>
                              </div>
                              <div>
                                  <label
                                      htmlFor="email-address"
                                      className="block pb-1"
                                  >
                                      Email address
                                  </label>
                                  <input
                                      {...register('email', { required: true })}
                                      id="email-address"
                                      name="email"
                                      type="email"
                                      autoComplete="email"
                                      required
                                      className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                      placeholder="Email address"
                                  />
                                  {errors.email && (
                                      <span>This field is required</span>
                                  )}
                              </div>

                              <div>
                                  <div className="mt-5 sm:mt-8 flex justify-center justify-start">
                                      <div className="w-1/2 rounded-md">
                                          <a
                                              href="/login"
                                              className="flex w-full items-center rounded-md border border-transparent bg-transparent text-base font-medium text-indigo-700 py-2"
                                          >
                                              Back to login
                                          </a>
                                      </div>
                                      {isReset ? (
                                          <div className="w-1/2 mt-0 ml-3">
                                              Password reset link sent
                                              successfully!
                                          </div>
                                      ) : (
                                          <div className="w-1/2 mt-0 ml-3">
                                              <button className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 text-base font-medium text-white hover:bg-indigo-700 p-2">
                                                  Reset
                                              </button>
                                              {authError && (
                                                  <p className="bg-red-700 text-gray-50 text-center p-1 m-2 rounded font-mon text-sm">
                                                      {authError}
                                                  </p>
                                              )}
                                          </div>
                                      )}
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          )}
      </>
  );
};

export default Forgot;
