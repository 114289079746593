const AdminDashboard = () => {
    return (
        <div>
            <h1 className="text-4xl">Dashboard</h1>
            <p className="py-12">
                You can use this section of the app to modify system settings.
                It is accessible by admin users only
            </p>
        </div>
    );
};

export default AdminDashboard;
