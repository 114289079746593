import React from "react";

const LogoWhite = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 535 172"
      className={className}>
      <path
        fill="#ffffff"
        d="M161.91,65.59c10.61,0,18.02,7.41,18.02,18.02s-7.41,18.02-18.02,18.02-18.02-7.41-18.02-18.02,7.41-18.02,18.02-18.02m0-8.59c-16.01,0-27.2,11.06-27.2,26.91s11.03,26.32,26.22,26.32c8.32,0,15.29-3.43,19.48-9.39v8.12h8.59v-25.05c0-15.84-11.14-26.91-27.1-26.91"
      />
      <path
        fill="#ffffff"
        d="M226.91,89.95c0,7.65-3.84,11.69-11.1,11.69s-11.1-4.04-11.1-11.69v-23.19h30.91v-8.5h-30.91v-17.05h-9.08v48.74c0,12.7,7.54,20.28,20.18,20.28s19.99-7.58,19.99-20.28v-2.35h-8.88v2.35Z"
      />
      <path
        fill="#ffffff"
        d="M250.79,78.63c1.87-7.97,8.57-13.04,17.46-13.04s15.44,5.07,17.35,13.04h-34.81Zm17.46-21.63c-15.76,0-27.2,11.19-27.2,26.61s11.44,26.62,27.2,26.62c11.34,0,20.87-5.94,24.86-15.5l.74-1.77h-9.78l-.36,.65c-2.9,5.17-8.39,8.02-15.46,8.02-9.4,0-16.38-5.81-17.76-14.6h44.76v-3.42c0-15.42-11.36-26.61-27-26.61"
      />
      <path
        fill="#3bbec8"
        d="M369.91,89.95c0,7.65-3.84,11.69-11.1,11.69s-11.1-4.04-11.1-11.69v-23.19h30.91v-8.5h-30.91v-17.05h-9.08v48.74c0,12.7,7.54,20.28,20.18,20.28s19.99-7.58,19.99-20.28v-2.35h-8.88v2.35Z"
      />
      <path
        fill="#4cc2ca"
        d="M425.43,84.39c0,10.61-5.97,16.95-15.97,16.95s-15.97-6.18-15.97-16.95v-26.13h-9.08v26.13c0,15.75,9.23,25.54,24.08,25.54,7.19,0,13.06-2.63,16.95-7.5v3.7c0,10.86-5.47,16.36-16.27,16.36-5.39,0-12.42-1.55-15.28-8.93l-.31-.82h-9.1l.47,1.63c2.98,10.28,12.03,16.42,24.22,16.42,16.11,0,25.35-9.06,25.35-24.86V58.26h-9.08v26.13Z"
      />
      <path
        fill="#4dc3cb"
        d="M473.62,87.6v2.35c0,7.65-3.84,11.69-11.1,11.69s-11.1-4.04-11.1-11.69v-23.19h30.91v-8.5h-30.91v-17.05h-9.08v48.74c0,12.7,7.54,20.28,20.18,20.28s19.99-7.58,19.99-20.28v-2.35h-8.88Z"
      />
      <path
        fill="#ffffff"
        d="M323.42,57c-13.49,0-21.55,8.16-21.55,21.84v30.12h9.08v-30.12c0-8.67,4.31-13.25,12.46-13.25,2.84,0,5.27,.58,7.2,1.72l1.93,1.14v-9.94l-.88-.29c-2.5-.81-5.27-1.22-8.24-1.22"
      />
      <path
        fill="#ffffff"
        d="M52.5,87.55v-29.18h9.69v29.18c0,9.39,5,13.57,12.04,13.57s12.04-4.39,12.04-13.57v-29.18h9.69v29.18c0,9.18,5.1,13.57,11.94,13.57s12.04-4.18,12.04-13.57v-29.18h9.69v29.18c0,15.4-8.98,22.55-20.81,22.55-7.86,0-14.28-3.47-17.75-10-3.47,6.53-10,10-17.75,10-11.83,0-20.81-7.14-20.81-22.55"
      />
    </svg>
  );
};

export default LogoWhite;
