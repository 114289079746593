import { addDoc, collection } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { myFirestore } from './config';

const AdminPhaseAdd = () => {
    let navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async data => {
        console.log(data);
        const myCollection = collection(myFirestore, 'phase');
        const record = await addDoc(myCollection, data);
        console.log(record, `/admin/phase/${record.id}`);
        navigate(`/admin/phase/${record.id}`);
    };

    return (
        <>
            <h1 className="text-4xl">Add Phase</h1>
            <div className="py-12 flex flex-col">
                <Link to="/admin/phase" className="mb-6">
                    Back to phase list
                </Link>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col border rounded px-2 pt-6 pb-3">
                        <label htmlFor="slug">Slug:</label>
                        <input
                            id="slug"
                            type="text"
                            {...register('slug', {
                                required: 'Slug is required',
                            })}
                            className="border rounded px-2 py-1"
                        />
                        {errors.slug && (
                            <span className="text-red-500 text-sm pt-3">
                                {errors.slug.message}
                            </span>
                        )}
                    </div>
                    <div className="pt-2">
                        <button
                            type="submit"
                            className="border rounded bg-gray-50 hover:bg-gray-100 px-3 py-1"
                        >
                            Add phase
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AdminPhaseAdd;
