import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../common/hooks/useAuth';
import TheSpinner from '../../components/global/TheSpinner';

const Register = () => {
  // importing firebase auth
  const PASSWORD_NOT_MATCH_MSG = "Passwords don't match";
  const PASSWORD_MATCH_MSG = "Passwords match";
  const PASSWORD_VALIDATE_MSG = 'Passwords minimum 6 characters';
  const { registerUser, isLoading, authError } = useAuth();
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [justType, setJustType] = useState(false);
  const [confirmPasswordMsg, setConfirmPasswordMsg] = useState();
  const [passwordMsg, setpasswordMsg] = useState();

  // react hook form
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setJustType(false);
    setpasswordMsg(undefined);
    setConfirmPasswordMsg(undefined);
    registerUser(data.email, data.password, data.name, navigate, location);
    reset({});
  };

  const confirmPwdChange = confirmPwd => {
      setJustType(true);
      setPasswordConfirm(confirmPwd);
      if (password) {
          if (password !== confirmPwd) {
              setConfirmPasswordMsg(PASSWORD_NOT_MATCH_MSG);
          } else {
              setConfirmPasswordMsg(PASSWORD_MATCH_MSG);
          }
          return;
      }
      setConfirmPasswordMsg(undefined);
  };

  const pwdChange = (pwd) => {
    setJustType(true);
    setPassword(pwd);
    if (pwd.length > 0 && pwd.length < 6) {
      setpasswordMsg(PASSWORD_VALIDATE_MSG);
    } else {
      setpasswordMsg(undefined);
    }
  }

  // redirect
  let navigate = useNavigate();
  let location = useLocation();

  const cleanUpErrorMsg = (errorMsg) => {
    return errorMsg
      .replace('Firebase:', '')
      .replace(/[{()}]/g, '')
      .replace('auth/', '')
      .replace(/-/g, ' ')
      .trim()
  }

  return (
      <>
          {isLoading ? (
              <TheSpinner />
          ) : (
              <div className="flex h-screen">
                  <div className="m-auto">
                      <div className="w-56 py-10 m-auto">
                          <img
                              src="/images/new-logo.png"
                              alt="Watertyt"
                              className="h-full w-full object-cover object-center"
                          />
                      </div>
                      <div className="card w-96 bg-white rounded-xl md:shadow-xl p-10">
                          <form
                              className="space-y-6"
                              onSubmit={handleSubmit(onSubmit)}
                          >
                              <div>
                                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                      Create account
                                  </h2>
                              </div>
                              <div>
                                  <label
                                      htmlFor="email-address"
                                      className="block font-bold pb-1"
                                  >
                                      Name
                                  </label>
                                  <input
                                      {...register('name', { required: true })}
                                      id="name"
                                      name="name"
                                      type="name"
                                      onChange={() => setJustType(true)}
                                      autoComplete="name"
                                      className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                      placeholder="Your name"
                                  />
                                  {errors.name && (
                                      <span className="text-danger text-xs">
                                          Name is required
                                      </span>
                                  )}
                              </div>
                              <div>
                                  <label
                                      htmlFor="email-address"
                                      className="block font-bold pb-1"
                                  >
                                      Email
                                  </label>
                                  <input
                                      {...register('email', { required: true })}
                                      id="email-address"
                                      name="email"
                                      type="email"
                                      onChange={() => setJustType(true)}
                                      autoComplete="email"
                                      className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                      placeholder="Email"
                                  />
                                  {errors.email && (
                                      <span className="text-danger text-xs">
                                          Email address is required
                                      </span>
                                  )}
                              </div>
                              <div>
                                  <label
                                      htmlFor="password"
                                      className="block font-bold pb-1"
                                  >
                                      Password
                                  </label>
                                  <input
                                      {...register('password', {
                                          required: true,
                                      })}
                                      id="password"
                                      name="password"
                                      type="password"
                                      minLength={6}
                                      autoComplete="current-password"
                                      className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                      placeholder="Password"
                                      onChange={e => pwdChange(e.target.value)}
                                  />
                                  {passwordMsg && (
                                      <span className="text-danger text-xs">
                                          {passwordMsg}
                                      </span>
                                  )}
                              </div>
                              <div>
                                  <label
                                      htmlFor="password"
                                      className="block font-bold pb-1"
                                  >
                                      Repeat password
                                  </label>
                                  <input
                                      {...register('confirm_password', {
                                          required: true,
                                          validate: val => {
                                              if (watch('password') !== val) {
                                                  return "Passwords don't match";
                                              }
                                          },
                                      })}
                                      id="confirm_password"
                                      name="confirm_password"
                                      type="password"
                                      autoComplete="confirm_password"
                                      className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                      placeholder="Password"
                                      onChange={e =>
                                          confirmPwdChange(e.target.value)
                                      }
                                  />
                                  {confirmPasswordMsg &&
                                      confirmPasswordMsg !== '' &&
                                      password &&
                                      password !== '' && (
                                          <span
                                              className={
                                                  confirmPasswordMsg ===
                                                  PASSWORD_MATCH_MSG
                                                      ? 'text-success text-xs'
                                                      : 'text-danger text-xs'
                                              }
                                          >
                                              {confirmPasswordMsg}
                                          </span>
                                      )}
                              </div>
                              <div>
                                  {authError &&
                                      authError !== '' &&
                                      justType === false && (
                                          <span className="text-danger text-xs">
                                              {cleanUpErrorMsg(authError)}
                                          </span>
                                      )}
                              </div>
                              <div>
                                  <div className="mt-5 sm:mt-8 flex justify-center justify-start">
                                      <div className="w-1/2 rounded-md">
                                          <a
                                              href="/"
                                              className="flex w-full items-center rounded-md border border-transparent bg-transparent text-base font-medium text-danger py-2"
                                          >
                                              Cancel
                                          </a>
                                      </div>
                                      <div className="w-1/2 ml-3">
                                          <button className="flex w-full items-center justify-center rounded-md border border-transparent bg-brand text-base font-medium text-white hover:bg-indigo-700 p-2">
                                              Create account
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          )}
      </>
  );
};

export default Register;
