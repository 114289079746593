export const StripLabel = (rawLabel) => {
  return rawLabel
    .replace(/1./g, '')
    .replace(/2./g, '')
    .replace(/3./g, '')
    .replace(/4./g, '')
    .replace(/5./g, '')
    .replace(/6./g, '')
    .replace(/7./g, '')
    .replace(/8./g, '')
    .replace(/9./g, '')
    .replace(/10./g, '')
    .replace(/\*/g, '')
    .replace(/\&gt;/g, '')
    .trim();
}
