import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../common/hooks/useAuth';
import TheSpinner from '../components/global/TheSpinner';
import { deselect } from '../features/projectSlice';
import {
    useGetProjectListQuery,
    useProjectCount,
} from '../features/projectsApi';

const ProjectList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { user } = useAuth();

    const {
        data: projects,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetProjectListQuery(user.accessToken);

    useEffect(() => {
        dispatch(deselect);
    }, [dispatch]);

    const count = useProjectCount();

    useEffect(() => {
        if (count === 1) {
            const project = projects.data[0];
            navigate(`/project/${project.project_id}/dashboard`);
        }
    }, [count]);

    if (isLoading) {
        return <TheSpinner />;
    }

    if (isError) {
        console.log('error', error, typeof error);
        return (
            <div>
                <h1 className="mb-5 text-5xl font-thin">
                    Congratulations, your account has been created.
                </h1>
                <a href="">Click here to continue.</a>
            </div>
        );
        // return <p>{error}</p>;
    }

    if (isSuccess) {
        if (projects && projects.data && projects.data.length === 1) {
            // Auto navigate if only one projects
            return (
                <Navigate
                    to={`/project/${projects.data[0].project_id}/dashboard`}
                    state={{ from: location }}
                ></Navigate>
            );
        } else if (projects && projects.data && projects.data.length > 1) {
            return (
                <main>
                    <h1 className="mb-5 text-3xl font-thin sm:text-5xl">
                        My Projects
                    </h1>
                    <div className="block w-full h-screen">
                        <div className="mb-3 overflow-x-auto rounded-xl bg-gray-50">
                            <table className="min-w-full">
                                <thead className="bg-white border-b">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-4 text-sm font-medium text-left text-gray-900"
                                        >
                                            Id
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-4 text-sm font-medium text-left text-gray-900"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-4 text-sm font-medium text-left text-gray-900"
                                        >
                                            Company Name
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projects.data.map(project => {
                                        return (
                                            <tr
                                                className="transition duration-300 ease-in-out bg-white border-b cursor-pointer hover:bg-gray-100"
                                                key={project.project_id}
                                                onClick={() =>
                                                    navigate(
                                                        `/project/${project.project_id}/dashboard`
                                                    )
                                                }
                                            >
                                                <td className="px-6 py-4 text-sm font-light text-gray-900 whitespace-nowrap">
                                                    {project.project_id}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-light text-gray-900 whitespace-nowrap">
                                                    {project.project_name}
                                                </td>
                                                <td className="px-6 py-4 text-sm font-light text-gray-900 whitespace-nowrap">
                                                    {project.company_name}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            );
        } else {
            return (
                <main>
                    <h1 className="mb-5 text-5xl font-thin">
                        Sorry, you don’t have any pool projects assigned to you.
                    </h1>
                    <h2>
                        Please contact Laguna to get access to your project.
                    </h2>
                </main>
            );
        }
    }

    return <></>;
};

export default ProjectList;
