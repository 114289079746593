import { collection, query, where } from 'firebase/firestore';
import { get } from 'lodash';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import DataTable from 'react-data-table-component';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { myFirestore } from '../admin/config';
import DisplayRichText from '../components/Common/DisplayRichText';
import TheSpinner from '../components/global/TheSpinner';
import IconPaid from '../components/icons/Check';
import IconNotIssued from '../components/icons/Circle';
import IconOverdue from '../components/icons/Cross';
import IconUpcoming from '../components/icons/Minus';
import { useSelectedProject } from '../features/projectSlice';
import '../index.css';

const DollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const columns = [
    {
        name: 'Invoice #',
        selector: row => row.number,
        cell: row => (
            <a
                href={row.download}
                target="_blank"
                download
                rel="noopener noreferrer"
                className="underline cursor-pointer hover:no-underline text-nav-300 font-bold"
            >
                {row.number}
            </a>
        ),
    },
    {
        name: 'Reference',
        selector: row => row.ref,
        cell: row =>
            row.download ? (
                <a
                    href={row.download}
                    target="_blank"
                    download
                    rel="noopener noreferrer"
                    className="underline cursor-pointer hover:no-underline text-nav-300 font-bold"
                >
                    {row.ref}
                </a>
            ) : (
                <span>{row.ref}</span>
            ),
    },
    {
        name: 'Amount',
        selector: row => DollarFormatter.format(row.amount),
        style: { color: '#000000', fontWeight: 'bold' },
    },
    {
        name: 'Due Date',
        selector: row => row.duedate,
        style: { color: '#000000', fontWeight: 'bold' },
        cell: row => {
            const date = moment(row.duedate, 'DD/MM/YYYY hh:mm');
            return (
                <span className="whitespace-nowrap">
                    {date.isValid() ? date.format('DD/MM/YYYY') : ''}
                </span>
            );
        },
    },
    {
        name: 'Status',
        selector: row => row.status,
        minWidth: '100px',
        style: { fontWeight: 'normal' },
        cell: row => {
            let cellStyles = {};
            let content = '';
            let status = row.status;
            switch (row.status) {
                case 'paid':
                    content = <IconPaid color={'#bbc392'} />;
                    cellStyles = { color: '#bbc392', alignItems: 'center' };
                    break;
                case 'upcoming':
                    content = <IconUpcoming color={'#dea36d'} />;
                    cellStyles = { color: '#dea36d', alignItems: 'center' };
                    status = 'Upcoming Payment Due';
                    break;
                case 'unpaid':
                    content = <IconUpcoming color={'#dea36d'} />;
                    cellStyles = { color: '#dea36d', alignItems: 'center' };
                    status = 'Upcoming Payment Due';
                    break;
                case 'overdue':
                    content = <IconOverdue color={'#e0656a'} />;
                    cellStyles = { color: '#e0656a', alignItems: 'center' };
                    break;
                default:
                    content = <IconNotIssued color={'#b0b3b5'} />;
                    cellStyles = { color: '#b0b3b5', alignItems: 'center' };
                    status = 'Not Yet Issued';
                    break;
            }
            return (
                <span
                    className="w-full flex flex-col text-center text-xs"
                    style={cellStyles}
                >
                    {content}
                    {status.toUpperCase()}
                </span>
            );
        },
    },
];

const tableStyles = {
    rows: {
        style: {
            minHeight: 91,
            borderBottom: '1px solid #d9dadb !important',
            fontSize: 16,
            textTransform: 'uppercase',
        },
    },
    headCells: {
        style: {
            minHeight: 91,
            fontSize: 16,
            fontWeight: 'normal',
            textTransform: 'uppercase',
        },
    },
};

const Invoices = () => {
    const [invoices, setInvoices] = useState([]);

    const project = useSelectedProject();

    const pageId = 'invoices';
    const [pageMatches, pageLoading, pageError] = useCollectionDataOnce(
        query(collection(myFirestore, 'page'), where('slug', '==', pageId))
    );
    const pageData = pageMatches?.[0] || null;

    // const { user } = useAuth();

    // const { data: content } = useGetPageContentQuery({
    //     pageName: 'invoices',
    //     token: user.accessToken,
    // });

    useEffect(() => {
        let results = [];
        if (project?.invoices) {
            const invoiceList = get(project, 'invoices.items', []);
            invoiceList.forEach(invoice => {
                results.push({
                    status:
                        invoice.status !== 'paid' &&
                        moment(invoice.deadline) < moment()
                            ? 'overdue'
                            : invoice.status,
                    number: invoice.no,
                    ref: invoice.reference_no,
                    date: moment(invoice.date).format('DD/MM/YYYY'),
                    duedate: moment(invoice.deadline).format('DD/MM/YYYY'),
                    download: get(invoice, 'download.url', ''),
                    amount: invoice.sum,
                });
            });
        }
        if (project?.scheduledInvoices) {
            const invoiceList = get(project, 'scheduledInvoices', []);
            invoiceList
                .filter(x => x.status === 'active')
                .forEach(invoice => {
                    results.push({
                        status: 'upcoming',
                        number: invoice.no,
                        ref: invoice.reference_no || 'Stage Payment',
                        duedate: moment(invoice.next_generation_date)
                            .add(invoice.deadline_days, 'days')
                            .format('DD/MM/YYYY'),
                        download: get(invoice, 'download.url', ''),
                        amount: invoice.sum,
                    });
                });
        }
        setInvoices(
            results
                .sort(
                    (date1, date2) =>
                        moment(date2.duedate, `DD/MM/YYYY`) -
                        moment(date1.duedate, `DD/MM/YYYY`)
                )
                .reverse()
        );
    }, [project]);

    if (project && invoices) {
        return (
            <>
                <h1 className="text-3xl sm:text-5xl font-thin mb-5">
                    Invoices
                </h1>
                {/* <p className="font-bold text-lg mb-5">Project address - {project.project_name}</p> */}
                <div className="w-full space-y-6">
                    {pageLoading ? (
                        <TheSpinner />
                    ) : (
                        <DisplayRichText content={pageData?.description} />
                    )}
                </div>
                <div className="w-full my-12">
                    {/* <p className="font-bold text-lg mb-5">Files</p> */}
                    <DataTable
                        columns={columns}
                        data={invoices}
                        customStyles={tableStyles}
                    />
                </div>
            </>
        );
    } else {
        return <></>;
    }
};

export default Invoices;
