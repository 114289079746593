import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import projectSlice from '../features/projectSlice';
import { projectsApi } from '../features/projectsApi';
// import { todoSlice } from "../features/sample/todoSlice";

export const store = configureStore({
    reducer: {
        [projectsApi.reducerPath]: projectsApi.reducer,
        // [todoSlice.reducerPath]: todoSlice.reducer,
        project: projectSlice,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(projectsApi.middleware),
    // .concat(todoSlice.middleware),
    devTools: true,
});

setupListeners(store.dispatch);
