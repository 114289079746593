import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    AutoImage,
    AutoLink,
    Autosave,
    BlockQuote,
    Bold,
    ClassicEditor,
    Essentials,
    GeneralHtmlSupport,
    Heading,
    HorizontalLine,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    Italic,
    Link,
    LinkImage,
    List,
    MediaEmbed,
    Paragraph,
    RemoveFormat,
    RemoveFormatEditing,
    SelectAll,
    SourceEditing,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Undo,
} from 'ckeditor5';

// import 'ckeditor5/dist/ckeditor5.css';

const RichTextInput = ({ register, errors, title, id, setValue, data }) => {
    const { ref, onReady, onChange, onBlur, name, ...rest } = register;

    return (
        <div className="flex flex-col px-2 pt-6 pb-3 border rounded">
            <label htmlFor={id}>{title}:</label>

            <CKEditor
                id={id}
                editor={ClassicEditor}
                config={{
                    toolbar: {
                        items: [
                            'undo',
                            'redo',
                            '|',
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'link',
                            'insertImageViaUrl',
                            'mediaEmbed',
                            'insertTable',
                            'blockQuote',
                            'sourceEditing',
                            'removeFormat',
                            'RemoveFormatEditing',
                        ],
                        shouldNotGroupWhenFull: false,
                    },
                    plugins: [
                        AutoImage,
                        AutoLink,
                        Autosave,
                        BlockQuote,
                        Bold,
                        Essentials,
                        GeneralHtmlSupport,
                        Heading,
                        HorizontalLine,
                        ImageBlock,
                        ImageCaption,
                        ImageInline,
                        ImageInsertViaUrl,
                        ImageResize,
                        ImageStyle,
                        ImageTextAlternative,
                        ImageToolbar,
                        Italic,
                        Link,
                        LinkImage,
                        MediaEmbed,
                        Paragraph,
                        SelectAll,
                        SourceEditing,
                        Table,
                        TableCaption,
                        TableCellProperties,
                        TableColumnResize,
                        TableProperties,
                        TableToolbar,
                        Undo,
                        RemoveFormat,
                        RemoveFormatEditing,
                        List,
                    ],
                    heading: {
                        options: [
                            {
                                model: 'paragraph',
                                title: 'Paragraph',
                                class: 'ck-heading_paragraph',
                            },
                            {
                                model: 'heading1',
                                view: 'h1',
                                title: 'Heading 1',
                                class: 'ck-heading_heading1',
                            },
                            {
                                model: 'heading2',
                                view: 'h2',
                                title: 'Heading 2',
                                class: 'ck-heading_heading2',
                            },
                            {
                                model: 'heading3',
                                view: 'h3',
                                title: 'Heading 3',
                                class: 'ck-heading_heading3',
                            },
                            {
                                model: 'heading4',
                                view: 'h4',
                                title: 'Heading 4',
                                class: 'ck-heading_heading4',
                            },
                            {
                                model: 'heading5',
                                view: 'h5',
                                title: 'Heading 5',
                                class: 'ck-heading_heading5',
                            },
                            {
                                model: 'heading6',
                                view: 'h6',
                                title: 'Heading 6',
                                class: 'ck-heading_heading6',
                            },
                        ],
                    },
                    htmlSupport: {
                        allow: [
                            {
                                name: /^.*$/,
                                styles: true,
                                attributes: true,
                                classes: true,
                            },
                        ],
                    },
                    image: {
                        toolbar: [
                            'toggleImageCaption',
                            'imageTextAlternative',
                            '|',
                            'imageStyle:inline',
                            'imageStyle:wrapText',
                            'imageStyle:breakText',
                            '|',
                            'resizeImage',
                        ],
                    },
                    table: {
                        contentToolbar: [
                            'tableColumn',
                            'tableRow',
                            'mergeTableCells',
                            'tableProperties',
                            'tableCellProperties',
                        ],
                    },
                    mediaEmbed: {
                        // previewsInData: true,
                    },
                }}
                // {...register}
                {...rest}
                data={data[id] || ''}
                onChange={(value, editor) => setValue(id, editor.getData())}
            />

            {errors[id] && (
                <span className="pt-3 text-sm text-red-500">
                    {errors[id].message}
                </span>
            )}
        </div>
    );
};

export default RichTextInput;
