import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminDashboard from './admin/AdminDashboard';
import AdminLayout from './admin/AdminLayout';
import AdminPageAdd from './admin/AdminPageAdd';
import AdminPageEdit from './admin/AdminPageEdit';
import AdminPageList from './admin/AdminPageList';
import AdminPhaseAdd from './admin/AdminPhaseAdd';
import AdminPhaseEdit from './admin/AdminPhaseEdit';
import AdminPhaseList from './admin/AdminPhaseList';
import AuthProvider from './common/context/AuthProvider';
import PrivateRoute from './common/context/PrivateRoute';
import Error from './components/global/Error';
import MainLayout from './components/layouts/MainLayout';
import ProjectDetailLayout from './components/layouts/ProjectDetailLayout';
import CalendarPage from './pages/Calendar';
import Dashboard from './pages/Dashboard';
import Documents from './pages/Documents';
import Invoices from './pages/Invoices';
import Phase from './pages/Phase';
import Progress from './pages/Progress';
import ProjectList from './pages/ProjectList';
import Updates from './pages/Updates';
import Warranty from './pages/Warranty';
import Forgot from './pages/auth/Forgot';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    {/*
          <Route path="/" element={<Home />}></Route>
          <Route path="login" element={<Login />}></Route>
          <Route path="login/forgot" element={<Forgot />}></Route>
          <Route path="register" element={<Register />}></Route>
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/project-progress" element={<ProjectProgress />} />
          */}
                    <Route path="/" element={<Login />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/reset-password" element={<Forgot />}></Route>
                    <Route path="/register" element={<Register />}></Route>
                    <Route
                        path="/project"
                        element={
                            <PrivateRoute>
                                <MainLayout />
                            </PrivateRoute>
                        }
                    >
                        <Route path="/project" element={<ProjectList />} />
                    </Route>

                    <Route
                        path="/project"
                        element={
                            <PrivateRoute>
                                <ProjectDetailLayout />
                            </PrivateRoute>
                        }
                    >
                        {/* nested routes */}
                        <Route
                            path="/project/:projectId/dashboard"
                            element={<Dashboard />}
                        />
                        <Route
                            path="/project/:projectId/progress"
                            element={<Progress />}
                        />
                        <Route
                            path="/project/:projectId/documents"
                            element={<Documents />}
                        />
                        <Route
                            path="/project/:projectId/invoices"
                            element={<Invoices />}
                        />
                        <Route
                            path="/project/:projectId/updates"
                            element={<Updates />}
                        />
                        <Route
                            path="/project/:projectId/phase/:phase"
                            element={<Phase />}
                        />
                        <Route
                            path="/project/:projectId/warranty"
                            element={<Warranty />}
                        />
                        <Route
                            path="/project/:projectId/calendar"
                            element={<CalendarPage />}
                        />
                    </Route>

                    <Route path="/admin" element={<AdminLayout />}>
                        <Route path="/admin" element={<AdminDashboard />} />
                        <Route
                            path="/admin/phase"
                            element={<AdminPhaseList />}
                        />
                        <Route
                            path="/admin/phase/add"
                            element={<AdminPhaseAdd />}
                        />
                        <Route
                            path="/admin/phase/:id"
                            element={<AdminPhaseEdit />}
                        />
                        <Route path="/admin/page" element={<AdminPageList />} />
                        <Route
                            path="/admin/page/add"
                            element={<AdminPageAdd />}
                        />
                        <Route
                            path="/admin/page/:id"
                            element={<AdminPageEdit />}
                        />
                    </Route>

                    <Route path="*" element={<Error />}></Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
