import { NavLink } from 'react-router-dom';

const Link = ({ to, isExternal, children, ...attrs }) => {
  if (isExternal) {
    return (
      <a href={to} {...attrs}>
        {children}
      </a>
    );
  }

  return (
    <NavLink to={to} {...attrs}>
      {children}
    </NavLink>
  );
};

export default Link;
