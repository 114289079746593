import React from 'react';

const ChevronDown = ({ className }) => {
    return (
        <svg
            width="20"
            height="10"
            viewBox="0 0 20 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.252601 0.347387C0.619519 -0.0798321 1.25159 -0.118315 1.66437 0.261437L10 7.93005L18.3356 0.261437C18.7484 -0.118315 19.3805 -0.0798321 19.7474 0.347387C20.1143 0.774608 20.0772 1.42879 19.6644 1.80854L11.3287 9.47715C10.571 10.1743 9.42905 10.1743 8.67128 9.47715L0.335647 1.80854C-0.0771359 1.42879 -0.114317 0.774608 0.252601 0.347387Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ChevronDown;
