import React from 'react';

const Dashboard = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
      className={className}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="17.93px"
        d="m345.49,225.43h51.33m-168.02,80.34h51.33m-170.45-124.34h51.31m208.95-45.02v227.17m-114.86-227.17v227.17m-119.74-227.17v227.17m-90.37,54.48h410.06V81.93H44.97v336.13Z"
      />
    </svg>
  );
};

export default Dashboard;
