import { doc } from 'firebase/firestore';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Toaster } from 'react-hot-toast';
import { Navigate, useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';
import useAuth from '../common/hooks/useAuth';
import TheSpinner from '../components/global/TheSpinner';
import AdminSidebar from './AdminSidebar';
import { myFirestore } from './config';

const AdminLayout = () => {
    const { user, userLoading } = useAuth();
    const location = useLocation();

    const [roles, rolesLoading, rolesError] = useDocumentDataOnce(
        doc(myFirestore, 'roles/admin')
    );

    if (userLoading || rolesLoading) {
        return <TheSpinner />;
    }

    if (rolesError) {
        return <div>{rolesError}</div>;
    }

    // not logged in an empty object
    if (!user?.email) {
        return <Navigate to="/login" state={{ from: location }}></Navigate>;
    }

    // check for admin
    if (!roles.users.includes(user.email)) {
        return <div>Not an admin</div>;
    }

    return (
        <>
            <div>
                <Toaster />
            </div>
            <div className="flex h-screen">
                <div className="w-48 px-2 py-6 border-r">
                    <AdminSidebar />
                </div>
                <div className="flex-1 px-6 py-6">
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default AdminLayout;
