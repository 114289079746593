import React, { useCallback } from 'react';
import { useSelectedProject } from '../../features/projectSlice';
import IconUserCircle from '../icons/UserCircle';

const SidebarClient = () => {
    const project = useSelectedProject();

    const address = useCallback(() => {
        if (!project) return '';
        return project.custom_fields?.length > 0
            ? project.custom_fields.find(x => x.id === 'c_siteaddress')?.value
            : '';
    }, [project]);

    return (
        <div className="w-full shadow-2xl text-[#99dadf] flex items-center bg-white rounded-l-large rounded-r-large md:rounded-r-none py-5 px-5">
            <div className="mr-3">
                <IconUserCircle className="w-14" />
            </div>
            <div className="uppercase text-sm font-bold">
                <div>{project?.company_name}</div>
                <div>{address()}</div>
            </div>
        </div>
    );
};

export default SidebarClient;
