import React from 'react';

const Date = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
      className={className}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="17.63px"
        fill="none"
        d="m44.81,156.78h410.37M114.92,366.28h44.36m-44.36-65.81h44.36m-44.36-68.32h44.36m69.92,134.13h44.95m-44.95-65.81h44.95m-44.95-68.32h44.95m74.92,134.13h41.59m-41.59-65.81h41.59M137.19,98.66v-47.5m224.49,47.5v-47.5m-12.6,180.99h41.59M89.36,88.64h-44.54v360.2h410.37V88.64h-46.18m-219.76,0h119.87"
      />
    </svg>
  );
};

export default Date;
