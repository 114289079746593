import { collection, orderBy, query } from 'firebase/firestore';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { Link } from 'react-router-dom';
import TheSpinner from '../components/global/TheSpinner';
import { myFirestore } from './config';

const AdminPageList = () => {
    const [snapshot, loading, error] = useCollectionOnce(
        query(collection(myFirestore, 'page'), orderBy('slug'))
    );

    const renderBody = () => {
        if (loading) return <TheSpinner />;

        if (error) return <div>{error}</div>;

        if (snapshot.empty)
            return (
                <>
                    <Link to="/admin/page/add" className="mb-6">
                        Add a page
                    </Link>
                    <div className="mt-6 text-center border rounder py-12">
                        No pages yet
                    </div>
                </>
            );

        const getData = () => {
            const result = [];

            snapshot.forEach(doc => {
                const id = doc.id;
                const data = doc.data();
                result.push([id, data]);
            });

            return result;
        };

        return (
            <>
                <Link to="/admin/page/add" className="mb-6">
                    Add a page
                </Link>

                <div className="mt-6 text-center border rounder py-2 px-2">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="text-left px-2 py-1">Slug</th>
                                <th className="w-0"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {getData().map(([id, data]) => {
                                return (
                                    <tr key={id} className="hover:bg-gray-50">
                                        <td className="text-left px-2 py-1">
                                            {data.slug}
                                        </td>
                                        <td className="px-2 py-1">
                                            <Link to={`/admin/page/${id}`}>
                                                edit
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    return (
        <div>
            <h1 className="text-4xl">Pages</h1>
            <div className="py-12">{renderBody()}</div>
        </div>
    );
};

export default AdminPageList;
