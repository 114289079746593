import { useEffect } from 'react';

const DisplayRichText = ({ content }) => {
    useEffect(() => {
        if (!content) return;

        document.querySelectorAll('oembed[url]').forEach(element => {
            const anchor = document.createElement('a');

            anchor.setAttribute('href', element.getAttribute('url'));
            anchor.className = 'embedly-card';

            element.appendChild(anchor);
        });
    }, [content]);

    if (!content) return null;

    return (
        <>
            <div
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
                className="prose"
            />
        </>
    );
};

export default DisplayRichText;
