import React from 'react';
import UpdateList from '../components/global/UpdateList';

const updates = [
  {
    id: 1,
    title: 'New Invoice',
    date: '23/05/2022 14:56',
    description: 'At this stage we bring heavy equipment and destoy your backyard.',
    url: '#',
    tag: 'invoices',
  },
  {
    id: 2,
    title: 'New Invoice',
    date: '23/05/2022 14:56',
    description: 'At this stage we bring heavy equipment and destoy your backyard.',
    url: '#',
    tag: 'invoices',
  },
  {
    id: 3,
    title: 'New Files',
    date: '23/05/2022 14:56',
    description: 'At this stage we bring heavy equipment and destoy your backyard.',
    url: '#',
    tag: 'Files',
  },
  {
    id: 4,
    title: 'Misc',
    date: '23/05/2022 14:56',
    description: 'At this stage we bring heavy equipment and destoy your backyard.',
    url: '#',
    tag: 'Misc',
  },
];
const Updates = () => {
  return (
    <>
      <h1 className="text-5xl font-thin mb-5">Updates</h1>
      <p className="font-bold text-lg mb-20">Project address - 23 Ranga St., Melbourne</p>
      <div className="w-full">
        <UpdateList data={updates} />
      </div>
    </>
  );
};

export default Updates;
