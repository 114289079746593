import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import useAuth from '../common/hooks/useAuth';

export const projectsApi = createApi({
    reducerPath: 'projectsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_HOST}/api/project`,
    }),
    tagTypes: ['ProjectList', 'ProjectDetail'],
    endpoints: builder => ({
        getProjectList: builder.query({
            query: token => ({
                url: '/me',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            providesTags: ['ProjectList'],
        }),
        getProjectDetail: builder.query({
            query: ({ projectId, token }) => ({
                url: `/${projectId}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            providesTags: ['ProjectDetail'],
        }),
        retrieveFileBlob: builder.mutation({
            queryFn: async (
                { fileId, fileName, token },
                api,
                extraOptions,
                baseQuery
            ) => {
                const result = await baseQuery({
                    url: `/file/${fileId}`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    method: 'GET',
                    cache: 'no-cache',
                    responseHandler: response => response.blob(),
                });
                var hiddenElement = document.createElement('a');
                var url = window.URL || window.webkitURL;
                var blobPDF = url.createObjectURL(result.data);
                hiddenElement.href = blobPDF;
                hiddenElement.target = '_blank';
                hiddenElement.download = fileName;
                hiddenElement.click();
                return { data: null };
            },
        }),
        uploadFile: builder.mutation({
            query: ({ fileName, fileSize, projectId, chunkData, token }) => ({
                url: `/upload`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    data: { fileName, fileSize, projectId, chunkData },
                },
            }),
            invalidatesTags: ['ProjectDetail'],
        }),
    }),
});

export const {
    useGetProjectListQuery,
    useGetProjectDetailQuery,
    useRetrieveFileBlobMutation,
    useUploadFileMutation,
} = projectsApi;

export const useProjects = () => {
    const { user } = useAuth();
    const result = useGetProjectListQuery(user.accessToken);
    return result;
};

export const useProjectCount = () => {
    const projects = useProjects();
    const count = projects.data?.data.length || 0;
    return count;
};

