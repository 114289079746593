import { collection, query, where } from 'firebase/firestore';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { myFirestore } from '../admin/config';
import useAuth from '../common/hooks/useAuth';
import DisplayRichText from '../components/Common/DisplayRichText';
import TheSpinner from '../components/global/TheSpinner';
import { useProjectPhases, useSelectedProject } from '../features/projectSlice';
import { useRetrieveFileBlobMutation } from '../features/projectsApi';
import { File3dRenderCategoryId, FileDesignPlanCategoryId } from './Documents';

const Dashboard = () => {
    let { projectId } = useParams();
    const project = useSelectedProject();
    const {
        current: currentPhase,
        trueEndDate,
        trueStartDate,
    } = useProjectPhases();

    const pageId = 'dashboard';
    const [pageMatches, pageLoading, pageError] = useCollectionDataOnce(
        query(collection(myFirestore, 'page'), where('slug', '==', pageId))
    );
    const pageData = pageMatches?.[0] || null;

    const { user } = useAuth();

    const [retreiveFileBlob] = useRetrieveFileBlobMutation();

    function downloadFile({ fileId, fileName }) {
        retreiveFileBlob({ fileId, fileName, token: user.accessToken });
    }

    // const { data: content } = useGetPageContentQuery({
    //     pageName: 'dashboard',
    //     token: user.accessToken,
    // });

    const totalDays = moment.duration(trueStartDate.diff(trueEndDate)).asDays();
    const totalWorkDays = moment
        .duration(trueStartDate.diff(moment()))
        .asDays();
    const percentageComplete =
        moment() > trueEndDate
            ? 100
            : Math.ceil((totalWorkDays / totalDays) * 100);

    // Documents
    const fileList = get(project, 'files.items', []);
    const fileListSort = fileList
        .filter(
            x =>
                x.category_id !== FileDesignPlanCategoryId &&
                x.category_id !== File3dRenderCategoryId
        )
        .slice()
        .sort(
            (date1, date2) =>
                new Date(date2.datetime_created) -
                new Date(date1.datetime_created)
        )
        .slice(0, 4);

    const file3dRenders = fileList.some(
        file => file.category_id === File3dRenderCategoryId
    )
        ? fileList.filter(
              file => file.category_id === File3dRenderCategoryId
          )[0]
        : undefined;

    const fileDesignPlan = fileList.some(
        file => file.category_id === FileDesignPlanCategoryId
    )
        ? fileList.filter(
              file => file.category_id === FileDesignPlanCategoryId
          )[0]
        : undefined;

    // Invoice
    const invoiceList = get(project, 'invoices.items', []);
    const invoiceListSort = invoiceList
        .slice()
        .sort((date1, date2) => new Date(date2.date) - new Date(date1.date))
        .slice(0, 3);

    if (project) {
        return (
            <>
                <h1 className="text-3xl sm:text-5xl font-thin mb-5">
                    Welcome, {user.displayName}
                </h1>
                <p className="font-bold text-lg mb-5">{project.company_name}</p>

                <div className="w-full space-y-6">
                    {pageLoading ? (
                        <TheSpinner />
                    ) : (
                        <DisplayRichText content={pageData?.description} />
                    )}
                </div>
                {/* <div className="grid md:grid-cols-2 md:gap-6 xl:grid-cols-3 grid-flow-row pb-44">
                    <div className="card mb-3 rounded-xl bg-gray-50">
                        <div className="card-body p-5 md:p-7">
                            <h2 className="card-title text-2xl mb-8">
                                Project progress
                            </h2>
                            <div className="grid grid-cols-3 align-items-center">
                                <div className="text-center text-2xs sm:text-lg lg:text-2xs">
                                    Start date
                                    <br />
                                    <strong>
                                        {trueStartDate.format('D MMM YYYY')}
                                    </strong>
                                </div>
                                <div>
                                    <CircularProgressbarWithChildren
                                        value={percentageComplete}
                                        strokeWidth="12"
                                        styles={buildStyles({
                                            pathColor: '#244a4b',
                                        })}
                                    >
                                        <strong>{percentageComplete}%</strong>
                                        <span className="text-2xs">Ready</span>
                                    </CircularProgressbarWithChildren>
                                </div>
                                <div className="text-center text-2xs sm:text-lg lg:text-2xs">
                                    Project ETA
                                    <br />
                                    <strong>
                                        {trueEndDate.format('D MMM YYYY')}
                                    </strong>
                                </div>
                            </div>
                            {currentPhase && (
                                <div>
                                    <p className="font-semibold mb-2 mt-4">
                                        Current stage
                                    </p>
                                    <div className="bg-white rounded-xl p-4 align-items-center">
                                        <h4 className="font-semibold text-2xl">
                                            {StripLabel(currentPhase.title)}
                                            <svg
                                                className="float-right"
                                                width="32"
                                                height="28"
                                                viewBox="0 0 32 28"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.1152 5.24651C16.5875 5.24651 17.7814 4.07229 17.7814 2.62298C17.7814 1.17367 16.5875 0 15.1152 0C13.643 0 12.4485 1.17422 12.4485 2.62353C12.4485 4.07284 13.643 5.24651 15.1152 5.24651ZM11.6541 19.5411C10.2235 18.5977 8.75123 17.7232 7.23731 16.9099L3.26057 14.7775L0.0730647 25.7649C-0.197774 26.6455 0.300847 27.6649 1.29531 27.9318C2.28977 28.1987 3.21923 27.6584 3.4898 26.7291L5.52483 19.7232L8.89323 21.9311V26.244C8.89323 27.2077 9.69213 27.9936 10.671 27.9936C11.6499 27.9936 12.4489 27.2077 12.4489 26.244L12.449 20.9975C12.4485 20.4107 12.1485 19.8638 11.6541 19.5411ZM17.2875 22.7515C16.5164 22.7515 15.8458 23.2362 15.6025 23.947L14.2274 27.998H32L26.3388 16.7152C25.7065 15.4852 23.9443 15.4229 23.2221 16.6053L21.4504 19.5701L17.8247 17.6209L16.4914 10.9923C16.0097 8.82166 14.6263 6.98404 12.6207 5.9996C12.5652 5.97226 12.5027 5.96542 12.4471 5.93808C12.3221 5.87655 12.1831 5.87792 12.0442 5.79451C11.1985 5.4609 10.2929 5.24924 9.37624 5.24924H5.28727C4.77338 5.24924 4.28448 5.47134 3.94392 5.84702L0.835523 9.34286C0.793856 9.39754 0.752189 9.45903 0.710521 9.52055C0.217456 10.237 0.543851 11.3182 1.35664 11.7487L20.057 21.8282L19.5105 22.7509H17.2875V22.7515ZM5.88729 11.1624L4.58171 10.4586L6.14451 8.70189H8.05455L5.88729 11.1624ZM11.0874 14.0064L13.0263 11.7487L13.7763 15.4464L11.0874 14.0064Z"
                                                    fill="#09A5AE"
                                                />
                                            </svg>
                                        </h4>
                                        <p className="text-xs">
                                            <strong>In progress</strong>,{' '}
                                            {moment(
                                                currentPhase.end_date
                                            ).format('[ETA] D MMM, YYYY')}
                                        </p>
                                        <progress
                                            className="progress mt-2 text-primary"
                                            value="70"
                                            max="100"
                                        ></progress>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="card mb-3 rounded-xl bg-gray-50">
                        {fileListSort.length > 0 && (
                            <div className="card-body p-5 md:p-7">
                                <h2 className="card-title text-2xl align-middle mb-8">
                                    Documents{' '}
                                </h2>
                                {fileDesignPlan && (
                                    <div className="bg-white rounded-md flex px-2 py-1 align-items-center mb-2">
                                        <div className="font-semibold flex-auto text-sm">
                                            Design plan
                                        </div>
                                        <div className="flex-auto float-right text-right">
                                            <button
                                                onClick={() =>
                                                    downloadFile({
                                                        fileId: fileDesignPlan.file_id,
                                                        fileName:
                                                            fileDesignPlan.file_name,
                                                        token: user.accessToken,
                                                    })
                                                }
                                                className="px-2 py-1 bg-secondary inline-block rounded-sm border-0 text-xs ml-2"
                                            >
                                                Download
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {file3dRenders && (
                                    <div className="bg-white rounded-md flex px-2 py-1 align-items-center mb-2">
                                        <div className="font-semibold flex-auto text-sm">
                                            3D renders
                                        </div>
                                        <div className="flex-auto float-right text-right">
                                            <button
                                                onClick={() =>
                                                    downloadFile({
                                                        fileId: file3dRenders.file_id,
                                                        fileName:
                                                            file3dRenders.file_name,
                                                        token: file3dRenders.accessToken,
                                                    })
                                                }
                                                className="px-2 py-1 bg-secondary inline-block rounded-sm border-0 text-xs ml-2"
                                            >
                                                Download
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="mt-2 mb-1 font-semibold text-sm">
                                    New Documents
                                </div>
                                {fileListSort.map(file => {
                                    return (
                                        <div
                                            key={file.file_id}
                                            className="bg-white rounded-md flex px-2 py-1 align-items-center mb-2"
                                        >
                                            <div className="font-semibold flex-auto text-sm">
                                                {file.file_name}
                                            </div>
                                            <div className="flex-auto float-right text-right">
                                                <button
                                                    onClick={() =>
                                                        downloadFile({
                                                            fileId: file.file_id,
                                                            fileName:
                                                                file.file_name,
                                                            token: user.accessToken,
                                                        })
                                                    }
                                                    className="px-2 py-1 bg-secondary inline-block rounded-sm border-0 text-xs ml-2"
                                                >
                                                    Download
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="card-actions justify-center">
                                    <Button
                                        to={`/project/${projectId}/documents`}
                                    >
                                        Go to documents
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="card mb-3 rounded-xl bg-gray-50">
                        {invoiceListSort.length > 0 && (
                            <div className="card-body p-5 md:p-7">
                                <h2 className="card-title text-2xl mb-8">
                                    Invoices
                                </h2>
                                {invoiceListSort.map(invoice => {
                                    return (
                                        <div
                                            key={invoice.date}
                                            className="bg-white rounded-md flex px-2 py-1 align-items-center mb-2"
                                        >
                                            <div
                                                className={`font-semibold w-1/3 flex-auto text-sm ${
                                                    invoice.status === 'paid'
                                                        ? 'text-paid'
                                                        : moment(
                                                              invoice.deadline
                                                          ) < moment()
                                                        ? 'text-red-500'
                                                        : ''
                                                }`}
                                            >
                                                {invoice.status !== 'paid' &&
                                                moment(invoice.deadline) <
                                                    moment()
                                                    ? 'OVD'
                                                    : invoice.status.toUpperCase()}
                                            </div>
                                            <div className="flex-auto w-1/3 text-xs font-semibold">
                                                {invoice.no}
                                            </div>
                                            <div className="flex-auto w-1/3 float-right text-xs">
                                                {moment(invoice.date).format(
                                                    'DD/MM/YYYY'
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="card-actions justify-center">
                                    <Button
                                        to={`/project/${projectId}/invoices`}
                                    >
                                        Go to invoices
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div> */}
            </>
        );
    }
};

export default Dashboard;
