import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { useSelector } from 'react-redux';

const initialState = {
    selected: null,
};

export const defaultProjectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        select: (state, action) => {
            state.selected = action.payload;
        },
        deselect: state => {
            state.selected = null;
        },
    },
});

export const { select, deselect } = defaultProjectSlice.actions;

export const useSelectedProject = () => {
    const selector = useSelector(state => state.project.selected);
    return selector;
};

export const useProjectPhases = () => {
    const project = useSelectedProject();
    let trueStartDate = moment().add(-1, 'days');
    let trueEndDate = moment().add(-1, 'days');
    let current;
    let previous;
    let next;

    if (project?.phases?.length > 0) {
        if (project.phases[0].start_date) {
            trueStartDate = moment(project.phases[0].start_date);
        }
        if (project.phases[0].end_date) {
            trueEndDate = moment(project.phases[0].end_date);
        }

        for (let index = 1; index < project.phases.length; index++) {
            if (project.phases[index].type === 'phase') {
                if (project.phases[index].start_date) {
                    if (
                        moment(project.phases[index].start_date) < trueStartDate
                    ) {
                        trueStartDate = moment(
                            project.phases[index].start_date
                        );
                    }
                }
                if (project.phases[index].end_date) {
                    if (moment(project.phases[index].end_date) > trueEndDate) {
                        trueEndDate = moment(project.phases[index].end_date);
                    }
                }

                if (
                    project.phases[index].start_date &&
                    project.phases[index].end_date &&
                    moment() >= moment(project.phases[index].start_date) &&
                    moment() <= moment(project.phases[index].end_date)
                ) {
                    current = project.phases[index];
                }
            }
        }

        for (let index = 1; index < project.phases.length; index++) {
            if (
                project.phases[index].end_date &&
                moment(current?.start_date) >=
                    moment(project.phases[index]?.end_date) &&
                moment() >= moment(project.phases[index].end_date)
            ) {
                previous = project.phases[index];
            }
            if (
                project.phases[index].start_date &&
                moment() <= moment(project.phases[index].start_date) &&
                (!next ||
                    moment(project.phases[index].start_date) <=
                        moment(next?.start_date))
            ) {
                next = project.phases[index];
            }
        }
    }
    return {
        current,
        previous,
        next,
        trueStartDate,
        trueEndDate,
        phases: project?.phases?.length
            ? project.phases.filter(x => x.type === 'phase')
            : [],
    };
};

export default defaultProjectSlice.reducer;
