import SortAsc from '../icons/SortAsc';
import SortDesc from '../icons/SortDesc';
import SortNone from '../icons/SortNone';

const SortHeader = ({
    name,
    field,
    setField,
    asc,
    setAsc,
    defaultAsc = true,
    className,
    children,
}) => {
    const handleClick = () => {
        if (name === field) {
            setAsc(!asc);
        } else {
            setField(name);
            setAsc(defaultAsc);
        }
    };

    return (
        <th className={className}>
            <button
                onClick={handleClick}
                className={`w-full flex items-center ${className}`}
            >
                {children}
                {name !== field ? (
                    <SortNone className="pl-1 h-4 w-6 text-gray-500" />
                ) : asc ? (
                    <SortAsc className="pl-1 h-4 w-6 text-gray-500" />
                ) : (
                    <SortDesc className="pl-1 h-4 w-6 text-gray-500" />
                )}
            </button>
        </th>
    );
};

export default SortHeader;
