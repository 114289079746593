import classNames from "classnames";
import Link from "./Link";

const Button = ({ to, children, className }) => {
  const localClassName = classNames(
    'text-white px-4 py-1 rounded-lg bg-[#00a2ae] hover:bg-primaryHover',
    className
  )

  if (to) {
    return (
      <Link to={to} className={localClassName}>
        {children}
      </Link>
    )
  }

  return <button className={localClassName}>{children}</button>
}

export default Button;
