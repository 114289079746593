import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { useState } from "react";

const HoverComponent = ({ containerClass, toggleComponent, panelComponent, panelClass }) => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <div className={containerClass}>
      <div className="relative">
        <div onMouseEnter={() => setIsShowing(true)} onMouseLeave={() => setIsShowing(false)}>
          {toggleComponent}
        </div>
        <Transition
          show={isShowing}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className={classNames("absolute", panelClass)}>{panelComponent}</div>
        </Transition>
      </div>
    </div>
  );
};

export default HoverComponent;
