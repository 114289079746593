import _ from 'lodash';
import './ProgressCard.css';

export const STATUS_INPROGRESS = 'In Progress';
export const STATUS_COMPLETED = 'Completed';
export const STATUS_TOSTART = 'To Start';

const ProgressCard = ({ data, onSelect, isActive }) => {
  const progressStyle = {
    height: '5px',
  };

  const content = () => {
    switch (data.status) {
      case STATUS_COMPLETED:
        return (
          <>
            <div className="font-bold text-lg xl:text-xl inline-flex flex-wrap justify-between aa">
              <span className={isActive ? 'w-3/4' : 'opacity-50 w-3/4'}>
                {_.upperFirst(_.lowerCase(data.label))}
              </span>
              <svg
                className="float-right w-1/4"
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 17.5C0 7.83398 7.83398 0 17.5 0C27.166 0 35 7.83398 35 17.5C35 27.166 27.166 35 17.5 35C7.83398 35 0 27.166 0 17.5ZM25.416 14.4785C26.1611 13.7334 26.1611 12.5166 25.416 11.7715C24.6709 11.0264 23.4541 11.0264 22.709 11.7715L15.3125 19.168L12.291 16.1465C11.5459 15.4014 10.3291 15.4014 9.58398 16.1465C8.83887 16.8916 8.83887 18.1084 9.58398 18.8535L13.959 23.2285C14.7041 23.9736 15.9209 23.9736 16.666 23.2285L25.416 14.4785Z"
                  fill="#09A5AE"
                />
              </svg>
            </div>
            <p className={isActive ? 'text-xs ' : 'text-xs opacity-50 '}>
              <strong>{data.status}</strong> {data.dateLabel}
            </p>
            {isActive && (
              <progress className="progress mt-2 progress-primary" value="100" max="100"></progress>
            )}
          </>
        );
      case STATUS_INPROGRESS:
        return (
          <>
            <div className="font-bold text-lg inline-flex flex-wrap justify-between bb">
              <span className="w-3/4">{_.upperFirst(_.lowerCase(data.label))}</span>
              <svg
                className="float-right w-1/4"
                width="32"
                height="28"
                viewBox="0 0 32 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.1152 5.24651C16.5875 5.24651 17.7814 4.07229 17.7814 2.62298C17.7814 1.17367 16.5875 0 15.1152 0C13.643 0 12.4485 1.17422 12.4485 2.62353C12.4485 4.07284 13.643 5.24651 15.1152 5.24651ZM11.6541 19.5411C10.2235 18.5977 8.75123 17.7232 7.23731 16.9099L3.26057 14.7775L0.0730647 25.7649C-0.197774 26.6455 0.300847 27.6649 1.29531 27.9318C2.28977 28.1987 3.21923 27.6584 3.4898 26.7291L5.52483 19.7232L8.89323 21.9311V26.244C8.89323 27.2077 9.69213 27.9936 10.671 27.9936C11.6499 27.9936 12.4489 27.2077 12.4489 26.244L12.449 20.9975C12.4485 20.4107 12.1485 19.8638 11.6541 19.5411ZM17.2875 22.7515C16.5164 22.7515 15.8458 23.2362 15.6025 23.947L14.2274 27.998H32L26.3388 16.7152C25.7065 15.4852 23.9443 15.4229 23.2221 16.6053L21.4504 19.5701L17.8247 17.6209L16.4914 10.9923C16.0097 8.82166 14.6263 6.98404 12.6207 5.9996C12.5652 5.97226 12.5027 5.96542 12.4471 5.93808C12.3221 5.87655 12.1831 5.87792 12.0442 5.79451C11.1985 5.4609 10.2929 5.24924 9.37624 5.24924H5.28727C4.77338 5.24924 4.28448 5.47134 3.94392 5.84702L0.835524 9.34286C0.793856 9.39754 0.752189 9.45903 0.710521 9.52055C0.217456 10.237 0.543851 11.3182 1.35664 11.7487L20.057 21.8282L19.5105 22.7509H17.2875V22.7515ZM5.88729 11.1624L4.58171 10.4586L6.14451 8.70189H8.05455L5.88729 11.1624ZM11.0874 14.0064L13.0263 11.7487L13.7763 15.4464L11.0874 14.0064Z"
                  fill="#09A5AE"
                />
              </svg>
            </div>
            <p className="text-xs ">
              <strong>{data.status}</strong> {data.dateLabel}
            </p>
            {data.percentageComplete ? (
              <progress
                className="progress mt-3 progress-primary"
                value={data.percentageComplete}
                max="100"
                style={progressStyle}></progress>
            ) : (
              <></>
            )}
          </>
        );
      default:
        return (
          <>
            <div className="font-bold text-lg xl:text-xl inline-flex flex-wrap justify-between cc">
              <span>{_.upperFirst(_.lowerCase(data.label))}</span>
            </div>
            <p className="text-xs ">{data.dateLabel}</p>
            {isActive && (
              <progress className="progress mt-2 progress-primary" value="14" max="100"></progress>
            )}
          </>
        );
    }
  };
  return (
    <div
      onClick={() => onSelect(data)}
      className={(isActive ? 'active ' : '') + 'progress-card card mb-3 rounded-xl bg-gray-50 p-5'}>
      {content()}
    </div>
  );
};

export default ProgressCard;
