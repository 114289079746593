import React from 'react';

const Menu = ({ className }) => {
    return (
        <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 1C20 1.55229 19.5523 2 19 2L1 2C0.447716 2 0 1.55228 0 1C0 0.447715 0.447716 0 1 0H19C19.5523 0 20 0.447717 20 1ZM20 8C20 8.55229 19.5523 9 19 9L1 9C0.447716 9 0 8.55229 0 8C0 7.44772 0.447716 7 1 7L19 7C19.5523 7 20 7.44772 20 8ZM19 16C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14L1 14C0.447716 14 0 14.4477 0 15C0 15.5523 0.447716 16 1 16L19 16Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Menu;
