import { doc, setDoc } from 'firebase/firestore';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import TheSpinner from '../components/global/TheSpinner';
import { myFirestore } from './config';
import RichTextInput from './fields/RichTextInput';
import TextInput from './fields/TextInput';

const EditForm = ({ id, data }) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: data,
    });

    const onSubmit = async data => {
        const myCollection = doc(myFirestore, `page/${id}`);
        await setDoc(myCollection, data);
        toast.success('Page has been Saved');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-2">
                <TextInput
                    id="slug"
                    title="Unique Slug"
                    register={register('slug', {
                        required: 'Slug is required',
                    })}
                    errors={errors}
                />
                <RichTextInput
                    id="description"
                    title="Description"
                    register={register('description')}
                    setValue={setValue}
                    data={data}
                    errors={errors}
                />
                <div>
                    <button
                        type="submit"
                        className="border rounded bg-gray-50 hover:bg-gray-100 px-3 py-1"
                    >
                        Update page
                    </button>
                </div>
            </div>
        </form>
    );
};

const AdminPageEdit = () => {
    const { id } = useParams();

    const [data, loading, error] = useDocumentDataOnce(
        doc(myFirestore, `page/${id}`)
    );

    const renderBody = () => {
        if (loading) return <TheSpinner />;

        if (error) return <div>{error}</div>;

        return <EditForm id={id} data={data} />;
    };

    return (
        <>
            <h1 className="text-4xl">Edit Page</h1>
            <div className="py-12 flex flex-col">
                <Link to="/admin/page" className="mb-6">
                    Back to page list
                </Link>

                {renderBody()}
            </div>
        </>
    );
};

export default AdminPageEdit;
