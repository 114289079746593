import React from 'react';

const Setting = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
      className={className}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="17.76px"
        fill="none"
        d="m358.43,354.88l24.89,24.89m-114.44-167.07c-2.8,5.39,13.94-89.79-25.39-129.15-35.54-35.55-88.3-43.14-131.39-23.1l64.16,64.16-7.06,50.17-49.29,6.18L55.74,116.79c-20.01,43.11-12.41,95.87,23.13,131.39,39.33,39.31,134.5,22.6,129.15,25.37l153.29,176.88,58.96-28.46,34.85-65.35-186.25-143.91Z"
      />
    </svg>
  );
};

export default Setting;
