import { collection, query, where } from 'firebase/firestore';
import React from 'react';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { myFirestore } from '../admin/config';
import DisplayRichText from '../components/Common/DisplayRichText';
import TheSpinner from '../components/global/TheSpinner';

const Warranty = () => {
    const pageId = 'warranty';
    const [pageMatches, pageLoading, pageError] = useCollectionDataOnce(
        query(collection(myFirestore, 'page'), where('slug', '==', pageId))
    );
    const pageData = pageMatches?.[0] || null;

    return (
        <>
            <h1 className="text-5xl font-thin mb-5">Warranty & Service</h1>
            <div className="w-full space-y-6">
                {pageLoading ? (
                    <TheSpinner />
                ) : (
                    <DisplayRichText content={pageData?.description} />
                )}
            </div>
        </>
    );
};

export default Warranty;
