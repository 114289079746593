import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    useProjectPhases,
    useSelectedProject,
} from '../../features/projectSlice';
import TabComplete from '../icons/TabComplete';
import TabCurrent from '../icons/TabCurrent';

const ProjectStatusTabs = ({ tabs = [] }) => {
    const location = useLocation();
    const project = useSelectedProject();
    const { trueEndDate, trueStartDate } = useProjectPhases();

    const percentageComplete = useCallback(() => {
        const totalDays = moment
            .duration(trueStartDate.diff(trueEndDate))
            .asDays();
        const totalWorkDays = moment
            .duration(trueStartDate.diff(moment()))
            .asDays();
        return moment() > trueEndDate
            ? 100
            : Math.ceil((totalWorkDays / totalDays) * 100);
    }, [trueEndDate, trueStartDate]);

    const activeTab = tabs.find(tab => tab.current);

    return (
        <>
            <div className="flex m-2.5 justify-between md:justify-normal space-x-3 md:space-x-20 text-xs md:text-sm text-nav-500">
                <p>{percentageComplete()}% Complete</p>
                <Link to={activeTab?.link} className="">
                    Project Status: {project?.status_name}
                </Link>
            </div>
            <div className="xl:hidden">
                <MobileNav
                    tabs={tabs}
                    currentPath={location.pathname}
                ></MobileNav>
            </div>
            <div className="hidden xl:block">
                <DesktopNav
                    tabs={tabs}
                    currentPath={location.pathname}
                ></DesktopNav>
            </div>
        </>
    );
};

const MobileNav = ({ tabs = [], currentPath }) => {
    const activeIndex = tabs.findIndex(tab => tab.current);

    return (
        <div className="pb-3 overflow-auto">
            <ul className="flex">
                {tabs?.map((tab, index) => {
                    return (
                        <li
                            key={`tab-${index}`}
                            className={classNames(
                                "relative text-center text-sm leading-4 text-[#244a4b] font-bold mr-3 px-5 pt-2 pb-4 flex items-center after:content-[''] after:h-[6px] after:bg-[#e6f1f2] after:absolute after:left-0 after:bottom-0 after:w-full after:rounded-large",
                                activeIndex === index ? 'after:bg-nav-400' : ''
                            )}
                        >
                            <Link to={tab.link}>{tab.label}</Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

const DesktopNav = ({ tabs = [], currentPath }) => {
    const colouredTabs = useMemo(() => {
        const colors = [
            'bg-nav-100',
            'bg-nav-200',
            'bg-nav-300',
            'bg-nav-400',
            'bg-nav-500',
            'bg-nav-600',
            'bg-nav-700',
            'bg-nav-800',
            'bg-nav-900',
        ];

        if (!tabs?.length) return [];

        return tabs.map((tab, index) => {
            return {
                ...tab,
                ix: index,
                bgColor: colors[8 - (index % 9)],
            };
        });
    }, [tabs]);

    const activeIndex = colouredTabs.findIndex(tab => tab.current);
    const indexBeforeActive = activeIndex - 1;
    const indexAfterActive = activeIndex + 1;
    const firstIndex = 0;

    return (
        <div className="overflow-y-auto whitespace-nowrap">
            <ul className="py-6 pl-10">
                {colouredTabs?.map((tab, index) => {
                    let paddingClass = '';
                    let marginClass = '';

                    if (activeIndex === index) {
                        paddingClass = 'pl-12';
                        marginClass = '';
                    } else if (firstIndex === index) {
                        paddingClass = 'pl-6 pr-8';
                        marginClass = '-ml-10';
                    } else if (indexBeforeActive === index) {
                        paddingClass = 'pl-12 pr-8';
                        marginClass = '-ml-10 -mr-5';
                    } else if (indexAfterActive === index) {
                        paddingClass = 'pl-6 pr-8';
                        marginClass = 'ml-0';
                    } else {
                        paddingClass = 'pl-14 pr-8';
                        marginClass = '-ml-10';
                    }

                    return (
                        <li
                            index={index}
                            key={tab.ix}
                            className={classNames(
                                'inline-block',
                                'relative min-w-[100px]',
                                tab.customClass,
                                marginClass,
                                activeIndex === index
                                    ? 'z-20 bg-transparent'
                                    : `${tab.bgColor} shadow`,
                                {
                                    'rounded-r-full': true,
                                    // indexBeforeActive !== index,
                                    'rounded-l-full': index === firstIndex,
                                }
                            )}
                            style={{ zIndex: 100 - index }}
                        >
                            <Link
                                to={tab.link}
                                className={classNames(
                                    'h-full flex items-center text-xs lg:text-sm rounded-full text-center px-4 py-6 text-white',
                                    paddingClass,
                                    activeIndex === index
                                        ? 'transform scale-[1.2] shadow z-20 px-6 '
                                        : '',
                                    activeIndex === index ? tab.bgColor : ''
                                )}
                            >
                                {tab.label}
                                {tab.complete ? (
                                    <TabComplete className="h-5 pl-2 w-7" />
                                ) : null}
                                {tab.current ? (
                                    <TabCurrent className="h-5 pl-2 w-7" />
                                ) : null}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ProjectStatusTabs;
