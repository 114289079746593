import { collection, limit, query, where } from 'firebase/firestore';
import React, { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router';
import slugify from 'slugify';
import { myFirestore } from '../../admin/config';
import Button from '../Common/Button';

const Accordion = ({ item, isActive, onClick }) => {
    const { projectId } = useParams();
    const titleClass = isActive
        ? 'font-semibold text-1xl py-4 cursor-pointer'
        : 'text-1xl py-4 cursor-pointer';
    const contentClass = isActive ? 'pb-2' : 'hidden';

    // const { user } = useAuth();
    // const [trigger, { data: phaseData }] = useLazyGetPhaseContentQuery();

    const phase = slugify(item.label, { lower: true });

    const [phaseMatches] = useCollectionData(
        query(
            collection(myFirestore, 'phase'),
            where('slug', '==', phase),
            limit(1)
        )
    );

    const phaseData = phaseMatches?.[0];

    // useEffect(() => {
    //     if (isActive) {
    //         trigger({ phaseName: item.label, token: user.accessToken });
    //     }
    // }, [isActive, trigger]);

    // const phaseContent = useCallback(() => {
    //     if (!phaseData) return '';
    //     return phaseData.synopsis;
    // }, [phaseData]);

    const icon = isActive ? (
        <svg
            className="float-right"
            style={{ width: 20, height: 20 }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
        </svg>
    ) : (
        <svg
            className="float-right"
            style={{ width: 20, height: 20 }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
        </svg>
    );

    return (
        <div
            className={`accordion-item ${
                isActive ? 'active border-t' : 'border-t'
            }${item.id === 0 ? ' border-b' : ''}`}
        >
            <h3 className={titleClass} onClick={onClick}>
                {item.label}
                {icon}
            </h3>
            <div className={contentClass}>
                <p
                    dangerouslySetInnerHTML={{
                        __html: phaseData?.synopsis,
                    }}
                    className="prose"
                />
                <div className="mt-3">
                    <Button
                        to={`/project/${projectId}/phase/${slugify(item.label, {
                            lower: true,
                        })}`}
                        className="rounded-[20px] uppercase text-sm"
                    >
                        Find out more
                    </Button>
                </div>
            </div>
        </div>
    );
};
const Accordions = ({ data }) => {
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);
    const handleAccordionClick = index => {
        setActiveAccordionIndex(index === activeAccordionIndex ? -1 : index);
    };
    return (
        <div className="accordions">
            {data.map((item, index) => (
                <Accordion
                    key={item.index}
                    item={item}
                    isActive={activeAccordionIndex === index}
                    onClick={() => handleAccordionClick(index)}
                />
            ))}
        </div>
    );
};

export default Accordions;
