import React from 'react';

const ChevronUp = ({ className }) => {
    return (
        <svg
            width="20"
            height="10"
            viewBox="0 0 20 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.252601 9.65261C0.619519 10.0798 1.25159 10.1183 1.66437 9.73856L10 2.06995L18.3356 9.73856C18.7484 10.1183 19.3805 10.0798 19.7474 9.65261C20.1143 9.22539 20.0772 8.57121 19.6644 8.19146L11.3287 0.522851C10.571 -0.174284 9.42905 -0.174284 8.67128 0.522851L0.335647 8.19146C-0.0771359 8.57121 -0.114317 9.22539 0.252601 9.65261Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ChevronUp;
