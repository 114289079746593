import React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../common/hooks/useAuth';
import TheSpinner from '../../components/global/TheSpinner';

const Login = () => {
  const { loginUser, isLoading, user, authError } = useAuth();
  // react hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // redirect
  let navigate = useNavigate();
  let location = useLocation();

  // subnitting register form
  const onSubmit = (data) => {
    loginUser(data.email, data.password, navigate, location);
    reset({});
  };

  if (user?.email) {
    const redirectTo = location?.state?.from?.pathname || '/project';
    return <Navigate to={redirectTo} state={{ from: location }}></Navigate>;
  } else {
    return (
        <>
            {isLoading ? (
                <TheSpinner />
            ) : (
                <div className="flex h-screen">
                    <div className="m-auto">
                        <div className="w-56 py-10 m-auto">
                            <img
                                src="/images/new-logo.png"
                                alt="Watertyt"
                                className="h-full w-full object-cover object-center"
                            />
                        </div>
                        <div className="card w-96 bg-white rounded-xl md:shadow-xl p-10">
                            <form
                                className="space-y-6"
                                action="#"
                                method="POST"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div>
                                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                        Login
                                    </h2>
                                </div>
                                <div>
                                    <label
                                        htmlFor="email-address"
                                        className="block pb-1"
                                    >
                                        Email address
                                    </label>
                                    <input
                                        {...register('email', {
                                            required: true,
                                        })}
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Email address"
                                    />
                                    {errors.email && (
                                        <p className="bg-danger text-gray-50 text-center p-1 m-1 rounded font-mon text-sm">
                                            Email address is required
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <label
                                        htmlFor="password"
                                        className="block pb-1"
                                    >
                                        Password
                                    </label>
                                    <input
                                        {...register('password', {
                                            required: true,
                                        })}
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Password"
                                    />
                                    {errors.password && (
                                        <p className="bg-danger text-gray-50 text-center p-1 m-1 rounded font-mon text-sm">
                                            Password is required
                                        </p>
                                    )}
                                </div>
                                {authError && (
                                    <p className="bg-danger text-gray-50 text-center p-1 m-1 rounded font-mon text-sm">
                                        {authError}
                                    </p>
                                )}
                                <div className="text-xs text-right">
                                    <Link
                                        to="/reset-password"
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                        Forgot your password?
                                    </Link>
                                </div>
                                <div>
                                    <div className="mt-5 sm:mt-8 flex justify-center justify-start">
                                        <div className="w-1/2 rounded-md shadow">
                                            <Link
                                                to="/register"
                                                className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 text-base font-medium text-indigo-700 hover:bg-indigo-200 p-2"
                                            >
                                                Create account
                                            </Link>
                                        </div>
                                        <div className="w-1/2 ml-3">
                                            <button className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 text-base font-medium text-white hover:bg-indigo-700 p-2">
                                                Login
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
  }
};

export default Login;
