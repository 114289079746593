import { skipToken } from '@reduxjs/toolkit/query';
import { collection, query, where } from 'firebase/firestore';
import { get, sortBy } from 'lodash';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { myFirestore } from '../admin/config';
import useAuth from '../common/hooks/useAuth';
import DisplayRichText from '../components/Common/DisplayRichText';
import SortHeader from '../components/Common/SortHeader';
import FileUpload from '../components/global/FileUpload';
import TheSpinner from '../components/global/TheSpinner';
import {
    useGetProjectDetailQuery,
    useRetrieveFileBlobMutation,
    useUploadFileMutation,
} from '../features/projectsApi';

export const File3dRenderCategoryId = 25;
export const FileDesignPlanCategoryId = 26;

const fileTypeColors = {
    pdf: 'text-[#E0656A]',
    jpg: 'text-[#E0656A]',
    other: 'text-[#DEA36D]',
};

const Documents = () => {
    const [files, setFiles] = useState([]);

    const [downloadId, setDownloadId] = useState();

    const [filterKeywords, setFilterKeywords] = useState('');

    const [retrieveFileBlob, { isLoading: isFileOnFetching }] =
        useRetrieveFileBlobMutation();

    const [uploadFile, { isLoading: isFileOnUpload }] = useUploadFileMutation();

    const { projectId } = useParams();

    const pageId = 'documents';
    const [pageMatches, pageLoading, pageError] = useCollectionDataOnce(
        query(collection(myFirestore, 'page'), where('slug', '==', pageId))
    );
    const pageData = pageMatches?.[0] || null;

    const { user } = useAuth();

    const [sortField, setSortField] = useState('name');
    const [sortAsc, setSortAsc] = useState(true);

    const {
        data: project,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetProjectDetailQuery(
        projectId ? { projectId, token: user.accessToken } : skipToken
    );

    useEffect(() => {
        if (isSuccess && project && project.files) {
            const fileList = get(project, 'files.items', []);
            const fileListSort = fileList
                .slice()
                .sort(
                    (date1, date2) =>
                        new Date(date2.datetime_created) -
                        new Date(date1.datetime_created)
                );

            const results = [];
            fileListSort.forEach(file => {
                results.push({
                    id: file.file_id,
                    name: file.file_name,
                    type: file.file_type,
                    stage: '',
                    date: moment(file.datetime_created).format(
                        'DD/MM/YYYY HH:mm'
                    ),
                    categoryId: file.category_id,
                });
            });

            setFiles(results);
        }
    }, [isLoading, isSuccess, project]);

    // useEffect(() => {
    //     // retrieveFile(downloadId ? { projectId, token: user.accessToken } : skipToken);
    // }, [downloadId]);

    function downloadFile({ fileId, fileName }) {
        setDownloadId(fileId);
        retrieveFileBlob({ fileId, fileName, token: user.accessToken });
    }

    function fileUpload({ fileName, fileSize, chunkData }) {
        uploadFile({
            fileName,
            fileSize,
            projectId,
            chunkData,
            token: user.accessToken,
        });
    }

    if (isSuccess && project && files) {
        let filesFilter = files;

        if (filterKeywords) {
            filesFilter = files.filter(file =>
                get(file, 'name', '')
                    .toLowerCase()
                    .includes(filterKeywords.toLowerCase())
            );
        }

        // const file3dRenders = files.some(
        //     file => file.categoryId === File3dRenderCategoryId
        // )
        //     ? files.filter(
        //           file => file.categoryId === File3dRenderCategoryId
        //       )[0]
        //     : undefined;

        // const fileDesignPlan = files.some(
        //     file => file.categoryId === FileDesignPlanCategoryId
        // )
        //     ? files.filter(
        //           file => file.categoryId === FileDesignPlanCategoryId
        //       )[0]
        //     : undefined;

        filesFilter = sortBy(filesFilter, 'name');
        filesFilter = sortBy(filesFilter, sortField);

        if (!sortAsc) filesFilter.reverse();

        return (
            <>
                <h1 className="text-3xl sm:text-5xl font-thin mb-5">
                    My Pool/Documents
                </h1>
                {/* <p className="font-bold text-lg mb-5">Project address - {project.project_name}</p> */}
                <div className="w-full space-y-6">
                    {pageLoading ? (
                        <TheSpinner />
                    ) : (
                        <DisplayRichText content={pageData?.description} />
                    )}
                </div>

                <div className="my-20">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <SortHeader
                                    name="name"
                                    field={sortField}
                                    setField={setSortField}
                                    asc={sortAsc}
                                    setAsc={setSortAsc}
                                    className="text-left justify-start"
                                >
                                    File name
                                </SortHeader>
                                <SortHeader
                                    name="type"
                                    field={sortField}
                                    setField={setSortField}
                                    asc={sortAsc}
                                    setAsc={setSortAsc}
                                    className="text-center justify-center"
                                >
                                    Type
                                </SortHeader>
                                <SortHeader
                                    name="date"
                                    field={sortField}
                                    setField={setSortField}
                                    asc={sortAsc}
                                    setAsc={setSortAsc}
                                    defaultAsc={false}
                                    className="text-right justify-end"
                                >
                                    Date
                                </SortHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {filesFilter.map(file => {
                                const textColor = fileTypeColors[file.type]
                                    ? fileTypeColors[file.type]
                                    : '';

                                return (
                                    <tr key={file.id} className="">
                                        <td className="py-6 border-b text-left font-bold">
                                            {isFileOnFetching &&
                                            downloadId === file.id ? (
                                                <span>
                                                    <TheSpinner
                                                        size={30}
                                                        type="tree-dots"
                                                    />
                                                </span>
                                            ) : (
                                                <button
                                                    onClick={() =>
                                                        downloadFile({
                                                            fileId: file.id,
                                                            fileName: file.name,
                                                            token: user.accessToken,
                                                        })
                                                    }
                                                    className="uppercase text-left"
                                                >
                                                    {file.name}
                                                </button>
                                            )}
                                        </td>
                                        <td
                                            className={`py-6 border-b text-center font-bold uppercase ${textColor}`}
                                        >
                                            {file.type}
                                        </td>
                                        <td className="py-6 border-b text-right">
                                            {moment(
                                                file.date,
                                                'DD/MM/YYYY hh:mm'
                                            ).format('DD/MM/YYYY')}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="my-6 cursor-pointer">
                    <FileUpload
                        onFileUpload={fileUpload}
                        isLoading={isFileOnUpload}
                    ></FileUpload>
                </div>
            </>
        );
    } else if (isLoading) {
        return (
            <span>
                <TheSpinner />
            </span>
        );
    } else if (isError) {
        return <span>{error.message}</span>;
    } else {
        return <></>;
    }
};

export default Documents;
